import { Component, OnInit } from '@angular/core';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-weekly-enrollment-sales-tracking-report',
  templateUrl: './weekly-enrollment-sales-tracking-report.component.html',
  styleUrls: ['./weekly-enrollment-sales-tracking-report.component.css']
})
export class WeeklyEnrollmentSalesTrackingReportComponent extends ViewTableComponent implements OnInit {

  year: string;
  week: string;
  override WeeksForYearData: any[] = [];
  override LastFourYearsData: any[] = this.getLastFourYears();
  consumable: any;
  inquiriesData: any[] = [];
  deEnrollmentsData: any[] = [];
  preEnrollmentsData: any[] = [];
  oriData: any[] = [];
  extData: any[] = [];
  renData: any[] = [];
  nonUnitSalesData: any[] = [];
  miscSalesData: any[] = [];

  getCurrentWeek() {
    return moment().isoWeek().toString();
  }

  // constructor(private override _utilityService: UtilityService, private override _activatedRoute: ActivatedRoute) {
  // }

  override ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      const currentMoment = moment();
      this.year = params['year'] || currentMoment.format("YYYY");
      this.week = params['week'] || this.getCurrentWeek();
      this.onYearChange(this.year);
      this.loadData();
    });
  }

  onYearChange(year: string) {
    this.year = year;
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number(this.year));
  }
  override dataSource = new MatTableDataSource<any>([]);

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      const currentMoment = moment();
      this.week = data['week'] || currentMoment.isoWeek().toString();
      this.year = data['year'] || currentMoment.format("YYYY");
      this.loadData();
    });
  }

  loadData() {
    this.getData([
      { parameter: 'week', value: this.week.toString() },
      { parameter: 'year', value: this.year }
    ]);
  }

  override getData(params: { parameter: string; value: string }[]) {
    this.ShowProgressBar = true;
    params.push({ parameter: 'weekYear', value: this.year.toString() });
    params.push({ parameter: 'weekNumber', value: this.week.toString() });

    this._drupalRESTService.httpGET('/api_rest/v1/loadWeeklyEnrollmentSalesTrackingReport', params).subscribe(
      data => {
        this.ShowProgressBar = false;
        this.inquiriesData = (data?.['inquiry_merged_report'] || []).map(item => ({
          name: item.title,
          callDates: item.field_inquiry_date,
          bookingDates: item.field_booked_date
        }));
        this.deEnrollmentsData = data?.['dance_evaluation_report'] || [];
        this.preEnrollmentsData = data?.['pre_original_report'] || [];
        this.oriData = data?.['original_report'] || [];
        this.extData = data?.['extension_report'] || [];
        this.renData = data?.['renewal_report'] || [];
        this.nonUnitSalesData = data?.['non_unit_report'] || [];
        this.miscSalesData = data?.['miscellaneous_report'] || [];
      },
      error => this.handleError(error)
    );
  }

  private getLastFourYears(): any[] {
    const currentYear = moment().year();
    return Array.from({ length: 4 }, (_, i) => ({
      key: (currentYear - i).toString(),
      value: (currentYear - i).toString()
    }));
  }

  override onPrint() {
    window.print();
  }
}
