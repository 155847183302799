
<h2 mat-dialog-title>Add Terminal Device</h2>
<mat-dialog-content>
	<form [formGroup]="form">
		<mat-form-field>
			<input matInput placeholder="Device Name" formControlName="name">
			<mat-hint>Enter a descriptive name (e.g., "Front Desk", "Reception Area")</mat-hint>
		</mat-form-field>

		<mat-form-field>
			<mat-label>Square Location</mat-label>
			<mat-select formControlName="squareLocationId">
				<mat-option *ngFor="let location of locations" [value]="location.id">
					{{ location.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<button mat-button (click)="fetchLocations()">
			Refresh Locations
		</button>

	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button (click)="onCancel()">Cancel</button>
	<button mat-button [disabled]="!form.valid" (click)="onSubmit()">Add Device</button>
</mat-dialog-actions>
