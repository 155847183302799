import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SquareEventService } from 'src/app/services/square-event.service';
import { AuthService } from 'src/app/services/auth.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-square-connected-devices',
  templateUrl: './square-connected-devices.component.html',
  styleUrls: ['./square-connected-devices.component.css']
})
export class SquareConnectedDevicesComponent implements OnInit {
  stripeDevices: Array<any> = [];
  selectedDeviceId: string | null = null;
  showProgressSpinner = false;
  errorMessage: string;

  constructor(
    private squareEventService: SquareEventService,
    private authService: AuthService,
    private drupalRESTService: DrupalRESTService
  ) {}

  ngOnInit(): void {
    this.loadCurrentTerminalDeviceId();
    this.loadStripeDevices();

    // Subscribe to deauthorizeSquare events
    this.squareEventService.deauthorizeSquare$.subscribe(() => {
      this.clearStripeDevices();
    });
  }

  clearStripeDevices() {
    this.stripeDevices = [];
  }

  loadCurrentTerminalDeviceId() {
    const configFieldName = 'field_square_ref';
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
      .subscribe(
        (response) => {
          this.selectedDeviceId = response?.['field_square_terminal_device_id']?.[0]?.value || null;
        },
        (error) => {
          console.error('Error loading current terminal device ID:', error);
        }
      );
  }

  loadStripeDevices() {
    this.stripeDevices = [];
    this.showProgressSpinner = true;
    this.errorMessage = '';

    const studioId = this.authService.studios?.[0]?.id;
    if (!studioId) {
      this.errorMessage = 'No studio ID available.';
      this.showProgressSpinner = false;
      return;
    }

    this.drupalRESTService.httpGET(`/api/square/terminal/devices/${studioId}`).subscribe(
      (response: any) => {
        if (response.error) {
          this.errorMessage = response.error;
          this.showProgressSpinner = false;
          return;
        }
        this.stripeDevices = response.devices.map((device: any) => {
          const batteryDetails = device.components.find((c: any) => c.type === 'BATTERY')?.battery_details || {};
          const wifiDetails = device.components.find((c: any) => c.type === 'WIFI')?.wifi_details || {};
          return {
            ...device,
            battery: batteryDetails.visible_percent || 'N/A',
            wifi: wifiDetails.active ? wifiDetails : null,
            status: device.status,
          };
        });
        this.showProgressSpinner = false;
      },
      (error: any) => {
        console.error('Error loading Stripe devices:', error);
        if (error.status === 401) {
          this.errorMessage = 'Authentication failed. Please check your Square credentials.';
        } else {
          this.errorMessage = error.error?.error || 'An error occurred while loading devices.';
        }
        this.showProgressSpinner = false;
      }
    );
  }

  selectDevice(deviceId: string): void {
    this.selectedDeviceId = deviceId;
    this.saveSelectedDevice(deviceId);
  }

  saveSelectedDevice(deviceId: string): void {
    const configFieldName = 'field_square_ref';

    const postData = {
      config_field_name: configFieldName,
      config_data: {
        field_square_terminal_device_id: [{ value: deviceId }]
      }
    };

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Selected device saved successfully:', response);
        },
        (error) => {
          console.error('Error saving selected device:', error);
          this.errorMessage = 'Error saving selected device.';
        }
      );
  }
}
