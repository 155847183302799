import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import moment, { Moment } from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';
import { ScheduleConflictsComponent } from '../../utility/schedule-conflicts/schedule-conflicts.component';

@Component({
  selector: 'app-event-lesson-entity',
  templateUrl: './event-lesson-entity.component.html',
  styleUrls: ['./event-lesson-entity.component.css']
})

export class EventLessonEntityComponent extends BaseDialogComponent implements OnInit {

  override eckType = "events";
  override bundle = "lesson";
  override entityId = this._injectedDialogData['data']['EntityID'];

  entityType = "attendees";
  entityBundle = "attendance";

  field_instructor: any = null;

  conflictingEvent: any = null;
  alternativeTimes: any[] = [];

  @ViewChild('scheduleConflicts') scheduleConflicts: ScheduleConflictsComponent;

  studentIncrement(attendee: any) {
    console.log('attendee');
    console.log(attendee);

    // Append type & bundle to model.
    attendee = {
      ...attendee,
      type: 'attendees',
      bundle: 'attendance',
    }

    // If our data binding fails us (API needs to give an array), set as an empty array.
    if (!Array.isArray(this.field_student)) { this.field_student = [] }

    this.field_student.push(attendee);

    this.resetFieldStudent();
  }

  studentRemove = (index: number): void => {
    this.f.form.controls['__field_students_inline_form'].reset();
    this.editMode = false;
    this.field_student.splice(index, 1);

    this.resetFieldStudent();
  }

  studentUpdateLoad = (index: number): void => {
    this.resetFieldStudent();

    // Load the student enrollments again.
    this.lookupStudentAccountContact({ option: { value: this.field_student[index].field_student_account } });
    // Load the student contacts again.
    this.onStudentNameSelectThenLoadEnrollmentName({ option: { value: this.field_student[index].field_student_account } }, false, null, false, 'createLesson')

    // Append type & bundle to model.
    this.field_student[index]['type'] = 'attendees';
    this.field_student[index]['bundle'] = 'attendance';

    this.editIndex = index;
    this.editMode = true;

    // If field_student is a couple.
    this.field_student[index]['field_students'] = this.field_student[index]['field_students'].toString();

    Promise.resolve().then(() => {
      this.f.controls['__field_students_inline_form'].patchValue(this.field_student[index])
    })
  }

  studentUpdateSubmit(number: any, attendee: any) {
    this.field_student[this.editIndex] = attendee;
    this.f.form.controls['__field_students_inline_form'].reset();
    this.editMode = false;

    this.resetFieldStudent();
  }

  resetFieldStudent() {
    this.enrollmentMessage = "";
    this.autoCompleteStudentContactOptions = null;
    this.enrollmentsForUser = null;
    this.f.form.controls['__field_students_inline_form'].reset();
  }

  displayConflicts(conflicts: any[]) {
    // Format the conflicts data for display
    const formattedConflicts = conflicts.map(conflict => {
      return `Event ID: ${conflict.id}, Date and Time: ${conflict.date_and_time}, Instructor: ${conflict.instructor}`;
    });

    // Show the conflicts in an alert or a modal dialog
    alert(`Schedule conflicts found:\n${formattedConflicts.join('\n')}`);
  }

  async checkScheduleConflicts() {
    if (this.scheduleConflicts) {
      await this.scheduleConflicts.checkScheduleConflicts();
    }
  }

}

