import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { DrupalRESTService } from '../../../services/drupal-rest.service';

@Component({
  selector: 'app-add-terminal-device-dialog',
  templateUrl: './add-terminal-device-dialog.component.html',
  styleUrls: ['./add-terminal-device-dialog.component.css']
})
export class AddTerminalDeviceDialogComponent {
  form: FormGroup;
  locations: any[] = [];
  showProgressSpinner = false;
  errorMessage = '';

  constructor(
    private _authService: AuthService,
    private drupalRESTService: DrupalRESTService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddTerminalDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { locationId: string }
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      squareLocationId: [data.locationId || '', Validators.required]
    });

    this.fetchLocations();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        name: this.form.get('name')?.value,
        location_id: this.form.get('squareLocationId')?.value
      });
    }
  }

  fetchLocations() {
    // if (!this.isAuthorized) {
    //   this.errorMessage = 'Please authorize Square before fetching locations.';
    //   return;
    // }

    const studioId = this._authService.studios?.[0]?.id;
    this.showProgressSpinner = true;

    this.drupalRESTService.httpGET(`/api/square/locations/${studioId}`)
      .subscribe(
        (response: any) => {
          this.locations = response.locations || [];
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error fetching Square locations:', error);
          this.errorMessage = 'Error fetching Square locations: ' + (error.error?.error || 'Please try again.');
          this.showProgressSpinner = false;
        }
      );
  }
}
