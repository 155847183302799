<div class="container-fluid">
	<h1 class="page-header">Payroll Report</h1>

	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>Filter</mat-panel-title>
			<mat-panel-description>
				<app-filter-results-panel
					[parameters]="queryParams"></app-filter-results-panel>
			</mat-panel-description>
		</mat-expansion-panel-header>

		<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
			<div class="row">
				<div class="col-3">
					<mat-form-field class="full-width-field">
						<mat-label>Week</mat-label>
						<mat-select matInput [ngModel]="week" name="week"
							(selectionChange)="onSubmit(f)">
							<mat-option>None</mat-option>
							<mat-option *ngFor="let element of WeeksForYearData"
								[value]="element.key">
								{{ element.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="col-3">
					<mat-form-field class="full-width-field">
						<mat-label>Year</mat-label>
						<mat-select
							matInput
							[ngModel]="year"
							name="year"
							(selectionChange)="onYearChange($event); onSubmit(f)">
							<mat-option>None</mat-option>
							<mat-option *ngFor="let element of LastFourYearsData"
								[value]="element.key">
								{{ element.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<section class="section">
					<div class="float-end bring-inline">
						<button (click)="resetForm()" color="primary" mat-flat-button
							class="me-1">
							Clear
						</button>
					</div>
				</section>
			</div>
		</form>
	</mat-expansion-panel>

	<mat-progress-bar *ngIf="ShowProgressBar"
		mode="indeterminate"></mat-progress-bar>

	<!-- Report Header -->
	<table class="mat-table cdk-table mat-sort mt-4" *ngIf="data != undefined">
		<thead>
			<tr>
				<th class="mat-header-cell">Report Type</th>
				<th class="mat-header-cell">Prepared By</th>
				<th class="mat-header-cell">Year</th>
				<th class="mat-header-cell">Week Number</th>
				<th class="mat-header-cell">Date Range</th>
			</tr>
		</thead>
		<tr>
			<td class="mat-row">Payroll</td>
			<td class="mat-row">{{ data?.prepared_by }}</td>
			<td class="mat-row">{{ year }}</td>
			<td class="mat-row">{{ week }}</td>
			<td class="mat-row">{{ data?.date_range?.start | date }} - {{
				data?.date_range?.end | date }}</td>
		</tr>
	</table>

	<!-- Instructor Tables -->
	<div class="row">
		<div class="col-4 mt-4" *ngFor="let instructor of instructorData">
			<h3>{{instructor.instructorName}} <small
					class="text-muted">({{instructor.jobTitle}})</small></h3>

			<div class>
				<table mat-table [dataSource]="instructor.entries" class>
					<!-- Name Column -->
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>Name (job title)</th>
						<td mat-cell *matCellDef="let element"
							[class.fw-bold]="element.type === 'subtotal'">
							{{element.name}}
						</td>
					</ng-container>

					<!-- Sessions Column -->
					<ng-container matColumnDef="sessionCount">
						<th mat-header-cell *matHeaderCellDef># of Sessions</th>
						<td mat-cell *matCellDef="let element"
							[class.fw-bold]="element.type === 'subtotal'">
							{{element.sessionCount}}
						</td>
					</ng-container>

					<!-- Amount Paid Column -->
					<ng-container matColumnDef="amountPaid">
						<th mat-header-cell *matHeaderCellDef>Amount Paid</th>
						<td mat-cell *matCellDef="let element"
							[class.fw-bold]="element.type === 'subtotal'">
							{{element.amountPaid | currency}}
						</td>
					</ng-container>

					<!-- Total Column -->
					<ng-container matColumnDef="total">
						<th mat-header-cell *matHeaderCellDef>Total</th>
						<td mat-cell *matCellDef="let element"
							[class.fw-bold]="element.type === 'subtotal'">
							{{element.total | currency}}
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"
						[ngClass]="{'table-secondary': row.type === 'service' || row.type === 'schedule'}">
					</tr>
				</table>
			</div>
		</div>

		<div class="row mt-3">
			<div class="col-md-12">
				<div class="float-end">
					<button mat-raised-button color="primary" class="me-2"
						(click)="exportReport()">
						Export
					</button>
					<button mat-raised-button color="primary" (click)="refreshData()">
						Refresh
					</button>
				</div>
			</div>
		</div>
	</div>
