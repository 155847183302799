<!-- form-square-settings.component.html -->
<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>
      Square Settings
      <span class="environment-badge" [ngClass]="currentEnvironment">{{ currentEnvironment }}</span>
      <mat-icon *ngIf="isAuthorized" class="status-icon connected">check_circle</mat-icon>
      <mat-icon *ngIf="!isAuthorized" class="status-icon not-connected">error</mat-icon>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="squareSettingsForm">
      <div>
        <mat-progress-spinner
          class="matProgressSpinnerCentered"
          *ngIf="showProgressSpinner"
          mode="indeterminate">
        </mat-progress-spinner>

        <div class="col-12" *ngIf="!showProgressSpinner">
          <p *ngIf="isAuthorized" class="connection-status connected">
            You are connected to Square.
          </p>
          <p *ngIf="!isAuthorized" class="connection-status not-connected">
            You are not connected to Square. Please authorize below.
          </p>
        </div>

        <div class="tile-container">
          <div class="tile authorize-tile" (click)="authorizeSquare()" [class.disabled]="isAuthorized">
            <mat-icon class="tile-icon">vpn_key</mat-icon>
            <span class="tile-label">Authorize Square</span>
          </div>
          <div class="tile deauthorize-tile" (click)="deauthorizeSquare()" [class.disabled]="!isAuthorized">
            <mat-icon class="tile-icon">lock_open</mat-icon>
            <span class="tile-label">Deauthorize Square</span>
          </div>
          <div class="tile save-tile" (click)="onSubmit()" [class.disabled]="!isAuthorized">
            <mat-icon class="tile-icon">save</mat-icon>
            <span class="tile-label">Save Settings</span>
          </div>
          <div class="tile add-device-tile" (click)="addTerminalDevice()" [class.disabled]="!isAuthorized">
            <mat-icon class="tile-icon">add_circle</mat-icon>
            <span class="tile-label">Add Terminal Device</span>
          </div>
        </div>

        <div class="form-fields" *ngIf="isAuthorized">
          <h3 class="section-title">Square Location Settings</h3>

          <mat-form-field>
            <mat-label>Square Location</mat-label>
            <mat-select formControlName="squareLocationId" (selectionChange)="onLocationChange()">
              <mat-option *ngFor="let location of locations" [value]="location.id">
                {{ location.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="selected-location-details" *ngIf="selectedLocation">
            <div class="location-details">
              <span class="location-status" [ngClass]="selectedLocation.status?.toLowerCase()">
                {{ selectedLocation.status }}
              </span>
            </div>
            <div class="location-address" *ngIf="selectedLocation.address">
              {{ selectedLocation.address.address_line_1 }}<br>
              {{ selectedLocation.address.locality }}, {{ selectedLocation.address.administrative_district_level_1 }} {{ selectedLocation.address.postal_code }}
            </div>
          </div>

          <div class="location-actions">
            <button class="me-1" mat-button type="button" (click)="fetchLocations()">
              <mat-icon>refresh</mat-icon> Refresh Locations
            </button>

            <button mat-button type="button" (click)="onSubmit()" [disabled]="!isAuthorized">
              <mat-icon>save</mat-icon> Save Settings
            </button>
          </div>
        </div>

        <app-success-message [message]="successMessage"></app-success-message>
        <app-error-message [message]="errorMessage"></app-error-message>
      </div>
    </form>
  </mat-card-content>
</mat-card>
