<div class="container-fluid">
  <h1 class="page-header">Studio Business Summary Report</h1>

  <app-report-nav-menu [week]="this.week" [year]="this.year" [currentReport]="this.currentReport"></app-report-nav-menu>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Week</mat-label>
            <mat-select matInput [ngModel]=week name="week" (selectionChange)="onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of WeeksForYearData" [value]="element.key">
                {{ element.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Year</mat-label>
            <mat-select
              matInput
              [ngModel]="year"
              name="year"
              (selectionChange)="onYearChange($event); onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of LastFourYearsData" [value]="element.key">
                {{ element.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <section class="section">

          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>
      </div>


      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
            <button
              [disabled]="this.isDisabled"
              class="me-1"
              mat-raised-button color="primary"
              (click)="openDialog(SendUpdateAPIComponent, null, {
                endpoint: 'studio_business_send_api',
                operation: 'Send API',
                form: this.f.form.value
              });">Send API</button>
            <button
              [disabled]="this.isDisabled"
              class="me-1"
              mat-raised-button color="primary"
              (click)="openDialog(SendUpdateAPIComponent, null, {
                endpoint: 'studio_business_send_api',
                operation: 'Update API',
                form: this.f.form.value
              });">Update API</button>

              <button
                [routerLink]="['/ami-reports']"
                class="me-1"
                mat-raised-button color="secondary"
                >Back to list</button>
      </div>
    </div>
  </div>

  <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
    <thead role="rowgroup">
        <tr>
          <th class="mat-header-cell">Report Type</th>
          <th class="mat-header-cell">Prepared By</th>
          <th class="mat-header-cell">Year</th>
          <th class="mat-header-cell">Week Number</th>
          <th class="mat-header-cell">Date Range</th>
        </tr>
    </thead>
    <tr>
      <td class="mat-row">Studio Business</td>
      <td class="mat-row">{{ data['#prepared_by'] }}</td>
      <td class="mat-row">{{ data['#selectedData']['year'] }}</td>
      <td class="mat-row">{{ data['#selectedData']['week'] }}</td>
      <td class="mat-row">{{ data['#date_start'] | date }} - {{ data['#date_end'] | date }}</td>
    </tr>
  </table>

  <div class="row">
    <!-- {{ data?.['#studio_content_table']?.['studio_content_table']?.['#consumable'] | json}} -->
    <div class="col-6">
      <!-- CASH RECEIPTS -->
      <h2>Cash Receipts</h2>
      <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
        <thead role="rowgroup">
          <tr>
            <th class="mat-header-cell"></th>
            <th class="mat-header-cell">Lessons</th>
            <th class="mat-header-cell">Misc</th>
            <th class="mat-header-cell">Sundry</th>
            <th class="mat-header-cell">Totals</th>
          </tr>
        </thead>
        <tr>
          <td class="mat-row">Current</td>
          <td class="mat-row">${{ this.consumable?.cash }}</td>
          <td class="mat-row">${{ this.consumable?.miscellaneous }}</td>
          <td class="mat-row">${{ this.consumable?.sundry }}</td>
          <td class="mat-row">${{ this.consumable?.cash + this.consumable?.miscellaneous + this.consumable?.sundry }}</td>
        </tr>
        <tr>
          <td class="mat-row">Refund</td>
          <td class="mat-row">$({{ Math.abs(this.consumable?.refund_cash) }})</td>
          <td class="mat-row">$({{ Math.abs(this.consumable?.refund_miscellaneous) }})</td>
          <td class="mat-row">$({{ Math.abs(this.consumable?.refund_sundry) }})</td>
          <td class="mat-row">$({{ Math.abs(this.consumable?.refund_cash + this.consumable?.refund_miscellaneous + this.consumable?.refund_sundry) }})</td>
        </tr>
        <tr>
          <td class="mat-row">Total</td>
          <td class="mat-row">${{ this.consumable?.cash + this.consumable?.refund_cash }}</td>
          <td class="mat-row">${{ this.consumable?.miscellaneous + this.consumable?.refund_miscellaneous }}</td>
          <td class="mat-row">${{ this.consumable?.sundry + this.consumable?.refund_sundry }}</td>
          <td class="mat-row">
            ${{
              (this.consumable?.cash + this.consumable?.refund_cash) +
              (this.consumable?.miscellaneous + this.consumable?.refund_miscellaneous) +
              (this.consumable?.sundry + this.consumable?.refund_sundry)
            }}
          </td>
        </tr>
        <tr>
          <td class="mat-row">YTD</td>
          <td class="mat-row">${{ this.consumable?.cash_ytd }}</td>
          <td class="mat-row">${{ this.consumable?.miscellaneous_ytd }}</td>
          <td class="mat-row">${{this.consumable?.sundry_ytd}}</td>
          <td class="mat-row">
            ${{ Math.round(this.consumable?.cash_ytd + this.consumable?.miscellaneous_ytd + this.consumable?.sundry_ytd, 2) }}
          </td>
        </tr>
      </table>

      <!-- INQUIRIES -->
      <h2>Inquiries</h2>
      <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
        <thead role="rowgroup">
            <tr>
                <th class="mat-header-cell"></th>
                <th class="mat-header-cell">Contact</th>
                <th class="mat-header-cell">Booked</th>
                <th class="mat-header-cell">Showed</th>
            </tr>
        </thead>
        <tr>
            <td class="mat-row"> Current </td>
            <td class="mat-row"
                [matTooltip]="consumable?.contact_tooltip || ''"
                [matTooltipDisabled]="!consumable?.contact_tooltip">
                {{ consumable?.contact ?? '0' }}
            </td>
            <td class="mat-row"
                [matTooltip]="consumable?.booked_tooltip || ''"
                [matTooltipDisabled]="!consumable?.booked_tooltip">
                {{ consumable?.booked ?? '0' }}
            </td>
            <td class="mat-row"
                [matTooltip]="consumable?.showed_tooltip || ''"
                [matTooltipDisabled]="!consumable?.showed_tooltip">
                {{ consumable?.showed ?? '0' }}
            </td>
        </tr>
        <tr>
            <td class="mat-row"> YTD </td>
            <td class="mat-row"> {{ consumable?.contact_ytd }} </td>
            <td class="mat-row"> {{ consumable?.booked_ytd }} </td>
            <td class="mat-row"> {{ consumable?.showed_ytd }} </td>
        </tr>
    </table>


      <!-- LESSONS TAUGHT -->
      <h2>Lessons Taught</h2>
      <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
        <thead role="rowgroup">
            <tr>
                <th class="mat-header-cell"></th>
                <th class="mat-header-cell">DE/Bonus</th>
                <th class="mat-header-cell">Pvt. Int.</th>
                <th class="mat-header-cell">Pvt. Ren.</th>
                <th class="mat-header-cell">TTE Out</th>
                <th class="mat-header-cell"># in Class</th>
            </tr>
        </thead>
        <tr>
            <td class="mat-row">Current</td>
            <td class="mat-row">{{ this.consumable?.DE_lessons_taught }}</td>
            <td class="mat-row">{{ this.consumable?.PVT_interview_lessons_taught }}</td>
            <td class="mat-row">{{ this.consumable?.PVT_renewal_lessons_taught }}</td>
            <td class="mat-row"></td>
            <td class="mat-row">{{ this.consumable?.number_in_class }}</td>
        </tr>
        <tr>
            <td class="mat-row">YTD</td>
            <td class="mat-row">{{ this.consumable?.DE_lessons_taught_ytd }}</td>
            <td class="mat-row">{{ this.consumable?.PVT_interview_lessons_taught_ytd }}</td>
            <td class="mat-row">{{ this.consumable?.PVT_renewal_lessons_taught_ytd }}</td>
            <td class="mat-row"></td>
            <td class="mat-row">{{ this.consumable?.number_in_class_ytd }}</td>
        </tr>
        <tr>
            <td class="mat-row">Active**</td>
            <td class="mat-row"></td>
            <td class="mat-row">{{ this.consumable?.active_students_interview_dpt }}</td>
            <td class="mat-row">{{ this.consumable?.active_students_renewal_dpt }}</td>
            <td class="mat-row"></td>
            <td class="mat-row"></td>
        </tr>
    </table>


      <!-- DROPS -->
      <h2>Drops</h2>
      <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
        <thead role="rowgroup">
            <tr>
                <th class="mat-header-cell"></th>
                <th class="mat-header-cell">PORI</th>
                <th class="mat-header-cell">ORI</th>
                <th class="mat-header-cell">EXT</th>
                <th class="mat-header-cell">REN</th>
                <th class="mat-header-cell">NUNIT</th>
                <th class="mat-header-cell">MISC</th>
            </tr>
        </thead>
        <tr>
            <td class="mat-row">Units</td>
            <td class="mat-row">{{ this.consumable?.pre_original_units_dropped }}</td>
            <td class="mat-row">{{ this.consumable?.original_units_dropped }}</td>
            <td class="mat-row">{{ this.consumable?.extension_units_dropped }}</td>
            <td class="mat-row">{{ this.consumable?.renewal_units_dropped }}</td>
            <td class="mat-row">{{ this.consumable?.non_unit_units_dropped }}</td>
            <td class="mat-row">{{ this.consumable?.miscellaneous_units_dropped }}</td>
        </tr>
        <tr>
            <td class="mat-row">Sales</td>
            <td class="mat-row">${{ this.consumable?.pre_original_sales_dropped }}</td>
            <td class="mat-row">${{ this.consumable?.original_sales_dropped }}</td>
            <td class="mat-row">${{ this.consumable?.extension_sales_dropped }}</td>
            <td class="mat-row">${{ this.consumable?.renewal_sales_dropped }}</td>
            <td class="mat-row">${{ this.consumable?.non_unit_sales_dropped }}</td>
            <td class="mat-row">${{ this.consumable?.miscellaneous_sales_dropped }}</td>
        </tr>
        <tr>
            <td class="mat-row">YTD Units</td>
            <td class="mat-row">{{ this.consumable?.pre_original_units_dropped_ytd }}</td>
            <td class="mat-row">{{ this.consumable?.original_units_dropped_ytd }}</td>
            <td class="mat-row">{{ this.consumable?.extension_units_dropped_ytd }}</td>
            <td class="mat-row">{{ this.consumable?.renewal_units_dropped_ytd }}</td>
            <td class="mat-row">{{ this.consumable?.non_unit_units_dropped_ytd }}</td>
            <td class="mat-row">{{ this.consumable?.miscellaneous_units_dropped_ytd }}</td>
        </tr>
        <tr>
            <td class="mat-row">YTD Sales</td>
            <td class="mat-row">${{ this.consumable?.pre_original_sales_dropped_ytd }}</td>
            <td class="mat-row">${{ this.consumable?.original_sales_dropped_ytd }}</td>
            <td class="mat-row">${{ this.consumable?.extension_sales_dropped_ytd }}</td>
            <td class="mat-row">${{ this.consumable?.renewal_sales_dropped_ytd }}</td>
            <td class="mat-row">${{ this.consumable?.non_unit_sales_dropped_ytd }}</td>
            <td class="mat-row">${{ this.consumable?.miscellaneous_sales_dropped_ytd }}</td>
        </tr>
    </table>
    </div>

    <div class="col-6">
      <!-- UNIT SALES TRACKING -->
      <h2>Unit Sales Tracking</h2>
      <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
        <thead role="rowgroup">
            <tr>
              <th class="mat-header-cell">DE/Bonus</th>
              <th class="mat-header-cell">Tries</th>
              <th class="mat-header-cell">Sold</th>
              <th class="mat-header-cell">Units</th>
              <th class="mat-header-cell">Sales</th>
            </tr>
        </thead>
        <tr>
          <td class="mat-row">Current</td>
          <td class="mat-row">{{ this.consumable?.DE_tried }}</td>
          <td class="mat-row">{{ this.consumable?.DE_sold }}</td>
          <td class="mat-row">{{ this.consumable?.DE_units }}</td>
          <td class="mat-row">{{ this.consumable?.DE_sales }}-</td>
        </tr>
        <tr>
          <td class="mat-row">YTD</td>
          <td class="mat-row">{{ this.consumable?.DE_tried_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.DE_sold_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.DE_units_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.DE_sales_ytd }}-</td>
        </tr>
        <thead role="rowgroup">
            <tr>
              <th class="mat-header-cell">Pre-Original</th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
            </tr>
        </thead>
        <tr>
          <td class="mat-row">Current</td>
          <td class="mat-row">{{ this.consumable?.pre_original_tried }}</td>
          <td class="mat-row">{{ this.consumable?.pre_original_sold }}</td>
          <td class="mat-row">{{ this.consumable?.pre_original_units }}</td>
          <td class="mat-row">${{ this.consumable?.pre_original_sales }}</td>
        </tr>
        <tr>
          <td class="mat-row">YTD</td>
          <td class="mat-row">{{ this.consumable?.pre_original_tried_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.pre_original_sold_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.pre_original_units_ytd }}</td>
          <td class="mat-row">${{ this.consumable?.pre_original_sales_ytd }}</td>
        </tr>
        <thead role="rowgroup">
            <tr>
              <th class="mat-header-cell">Original</th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
            </tr>
        </thead>
        <tr>
          <td class="mat-row">Current</td>
          <td class="mat-row">{{ this.consumable?.original_tried }}</td>
          <td class="mat-row">{{ this.consumable?.original_sold }}</td>
          <td class="mat-row">{{ this.consumable?.original_units }}</td>
          <td class="mat-row">${{ this.consumable?.original_sales }}</td>
        </tr>
        <tr>
          <td class="mat-row">YTD</td>
          <td class="mat-row">{{ this.consumable?.original_tried_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.original_sold_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.original_units_ytd }}</td>
          <td class="mat-row">${{ this.consumable?.original_sales_ytd }}</td>
        </tr>
        <thead role="rowgroup">
            <tr>
              <th class="mat-header-cell">Extension</th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
            </tr>
        </thead>
        <tr>
          <td class="mat-row">Current</td>
          <td class="mat-row">{{ this.consumable?.extension_tried }}</td>
          <td class="mat-row">{{ this.consumable?.extension_sold }}</td>
          <td class="mat-row">{{ this.consumable?.extension_units }}</td>
          <td class="mat-row">${{ this.consumable?.extension_sales }}</td>
        </tr>
        <tr>
          <td class="mat-row">YTD</td>
          <td class="mat-row">{{ this.consumable?.extension_tried_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.extension_sold_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.extension_units_ytd }}</td>
          <td class="mat-row">${{ this.consumable?.extension_sales_ytd }}</td>
        </tr>
        <thead role="rowgroup">
            <tr>
              <th class="mat-header-cell">Renewal</th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
            </tr>
        </thead>
        <tr>
          <td class="mat-row">Current</td>
          <td class="mat-row">{{ this.consumable?.renewal_tried }}</td>
          <td class="mat-row">{{ this.consumable?.renewal_sold }}</td>
          <td class="mat-row">{{ this.consumable?.renewal_units }}</td>
          <td class="mat-row">${{ this.consumable?.renewal_sales }}</td>
        </tr>
        <tr>
          <td class="mat-row">YTD</td>
          <td class="mat-row">{{ this.consumable?.renewal_tried_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.renewal_sold_ytd }}</td>
          <td class="mat-row">{{ this.consumable?.renewal_units_ytd }}</td>
          <td class="mat-row">${{ this.consumable?.renewal_sales_ytd }}</td>
        </tr>
        <thead role="rowgroup">
            <tr>
              <th class="mat-header-cell">Total</th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
              <th class="mat-header-cell"></th>
            </tr>
        </thead>
        <tr>
          <td class="mat-row">Current</td>
          <td class="mat-row">
            {{
              this.consumable?.DE_tried
            + this.consumable?.pre_original_tried
            + this.consumable?.original_tried
            + this.consumable?.extension_tried
            + this.consumable?.renewal_tried
            }}
          </td>
          <td class="mat-row">
            {{
              this.consumable?.DE_sold
            + this.consumable?.pre_original_sold
            + this.consumable?.original_sold
            + this.consumable?.extension_sold
            + this.consumable?.renewal_sold
            }}
          </td>
          <td class="mat-row">
            {{
              this.consumable?.DE_units
            + this.consumable?.pre_original_units
            + this.consumable?.original_units
            + this.consumable?.extension_units
            + this.consumable?.renewal_units
            }}
          </td>
          <td class="mat-row">
            ${{
            + this.consumable?.pre_original_sales
            + this.consumable?.original_sales
            + this.consumable?.extension_sales
            + this.consumable?.renewal_sales
            }}
          </td>
        </tr>
        <tr>
          <td class="mat-row">YTD</td>
          <td class="mat-row">
            {{
              this.consumable?.DE_tried_ytd
            + this.consumable?.pre_original_tried_ytd
            + this.consumable?.original_tried_ytd
            + this.consumable?.extension_tried_ytd
            + this.consumable?.renewal_tried_ytd
            }}
          </td>
          <td class="mat-row">
            {{
              this.consumable?.DE_sold_ytd
            + this.consumable?.pre_original_sold_ytd
            + this.consumable?.original_sold_ytd
            + this.consumable?.extension_sold_ytd
            + this.consumable?.renewal_sold_ytd
            }}
          </td>
          <td class="mat-row">
            {{
              this.consumable?.DE_units_ytd
            + this.consumable?.pre_original_units_ytd
            + this.consumable?.original_units_ytd
            + this.consumable?.extension_units_ytd
            + this.consumable?.renewal_units_ytd
            }}
          </td>
          <td class="mat-row">
            ${{
            + this.consumable?.pre_original_sales_ytd
            + this.consumable?.original_sales_ytd
            + this.consumable?.extension_sales_ytd
            + this.consumable?.renewal_sales_ytd
            }}
          </td>
        </tr>
      </table>

      <div class="row">
        <div class="col-6">
          <!-- NON-UNIT SALES -->
          <h2>Non-Unit Sales</h2>
          <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
            <thead role="rowgroup">
                <tr>
                  <th class="mat-header-cell">PVT/Coach</th>
                  <th class="mat-header-cell">Lessons</th>
                  <th class="mat-header-cell">Sales</th>
                </tr>
            </thead>
            <tr>
              <td class="mat-row"> Current </td>
              <td class="mat-row"> {{ consumable?.non_unit_private_lessons }} </td>
              <td class="mat-row"> ${{ consumable?.non_unit_private_sales }} </td>
            </tr>
            <tr>
              <td class="mat-row"> YTD </td>
              <td class="mat-row"> {{ consumable?.non_unit_private_lessons_ytd }} </td>
              <td class="mat-row"> ${{ consumable?.non_unit_private_sales_ytd }} </td>
            </tr>
          </table>
          <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
            <thead role="rowgroup">
                <tr>
                  <th class="mat-header-cell">Class</th>
                  <th class="mat-header-cell"></th>
                  <th class="mat-header-cell"></th>
                </tr>
            </thead>
            <tr>
              <td class="mat-row"> Current </td>
              <td class="mat-row"> {{ consumable?.non_unit_class_lessons }} </td>
              <td class="mat-row"> ${{ consumable?.non_unit_class_sales }} </td>
            </tr>
            <tr>
              <td class="mat-row"> YTD </td>
              <td class="mat-row"> {{ consumable?.non_unit_class_lessons_ytd }} </td>
              <td class="mat-row"> ${{ consumable?.non_unit_class_sales_ytd }} </td>
            </tr>
          </table>
        </div>

        <div class="col-6">
          <!-- MISCELLANEOUS -->
          <h2>Miscellaneous</h2>
          <table class="mat-table cdk-table mat-sort mt-4" role="table" *ngIf="data != undefined">
            <thead role="rowgroup">
                <tr>
                  <th class="mat-header-cell">Events</th>
                  <th class="mat-header-cell">Sales</th>
                </tr>
            </thead>
            <tr>
              <td class="mat-row"> Current </td>
              <td class="mat-row"> ${{ consumable?.miscellaneous_sales }} </td>
            </tr>
            <tr>
              <td class="mat-row"> YTD </td>
              <td class="mat-row"> ${{ consumable?.miscellaneous_sales_ytd }} </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

  </div>

  <div class="table-container d-none">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

      <!-- name Column -->
      <ng-container matColumnDef="column_1">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Name">
          column_1
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container>{{ element[0].data }}</ng-container>
        </td>
      </ng-container>

      <!-- name Column -->
      <ng-container matColumnDef="column_2">
          <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Name">
              column_2
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container>{{ element[1] }}</ng-container>
          </td>
        </ng-container>

      <!-- REPLACE Column -->
      <!-- <ng-container matColumnDef="REPLACE">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
          REPLACE
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.REPLACE">{{ element.REPLACE }}</ng-container>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <ng-container *ngIf="this.pageSize == 0">
      <div>
        Please select criteria to generate a report.
      </div>
    </ng-container>

    <!-- <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="10"
      [disabled]="ShowProgressBar"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator> -->

  </div>
</div>
