import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  current_user: Object = {};
  csrf_token: string = "";
  logout_token: string = "";
  user_id: number;
  userAuthenticated: boolean = false;
  studios: any[] = [];
  userAgreedToAgreement: boolean = false;
  logRocketInitialized: boolean = false;

  is_user_authenticated() {
    return this.userAuthenticated;
  }

  set_user_authenticated(boolean: boolean) {
    this.userAuthenticated = boolean;
    // When user is authenticated, identify the user in LogRocket
    this.identifyUserInLogRocket();
  }

  // Identify the user in LogRocket
  async identifyUserInLogRocket(): Promise<void> {
    // Skip LogRocket for admin (uid = 1)
    if (this.current_user?.['uid'] == 1) {
      console.log('Admin user detected. Skipping LogRocket initialization.');
      return;
    }

    // Only initialize and identify for non-admin authenticated users
    if (this.userAuthenticated && this.current_user?.['uid'] && !this.logRocketInitialized) {
      try {
        const LogRocket = (await import('logrocket')).default;
        LogRocket.init(environment.logRocketKey);
        this.logRocketInitialized = true;

        LogRocket.identify(`${this.current_user['uid']}`, {
          name: this.current_user['name'],
          email: this.current_user['email'],
          studios: this.current_user['studios'] || 'None',
        });
      } catch (error) {
        console.error('Error loading LogRocket:', error);
      }
    }
  }

  has_user_agreed_to_agreement() {
    return this.userAgreedToAgreement;
  }

  set_user_agreed_to_agreement(agreed: boolean) {
    this.userAgreedToAgreement = agreed;
  }
}
