<!-- enrollment.component.html -->
<app-entity-mat-dialog-title title="Enrollment"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content class="mat-tab-header">

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

        <mat-tab-group class="eventTabGroup" [selectedIndex]="this.selectedIndex" (selectedTabChange)="setFormMode($event.index)" animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">

                <div class="container-fluid">
                    <div class="row">
                        <div id="enrollment-agreement">
                            <div class="page1">
                                <div class="row">
                                    <p class="text-center" style="font-size:18px; font-weight:bold;">ENROLLMENT AGREEMENT</p>

                                    <div class="col-6">
                                        <p><strong>Enrollment:</strong> {{ entityData?.['title'] }}</p>

                                        <p><strong>Student Name:</strong> {{ entityData?.['field_student']?.['title'] }} ({{ entityData?.['field_student']?.['id'] }})</p>

                                        <p><strong>Phone:</strong> {{ this.studentAccountEntityData?.field_contacts?.field_home_phone ? this.studentAccountEntityData.field_contacts.field_home_phone : '-' }} <strong>Cell:</strong> {{ this.studentAccountEntityData?.field_contacts?.field_cell_phone }}</p>

                                        <div class="row">
                                            <div class="col-6">
                                                <p><strong>Package Type:</strong> {{entityData?.['field_enrollment_package_name']?.['title'] }}</p>
                                            </div>
                                            <div class="col-6">
                                                <p><strong>Personal Lessons:</strong> {{ entityData?.['field_enrollment_lesson_count'] }}</p>
                                            </div>
                                        </div>

                                        <p><strong>Package Cost:</strong> ${{ getOriginalPackageCost() }} <!--${{ +entityData?.['field_enrollment_total_price'] }}--> <strong>Discount:</strong>
                                            <ng-container *ngIf="this.entityData?.field_discount_type == 'flat'">
                                                ${{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}
                                            </ng-container>
                                            <ng-container *ngIf="this.entityData?.field_discount_type == 'percentage'">
                                                {{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}%
                                            </ng-container>
                                            <ng-container *ngIf="this.entityData?.field_discount_type != 'percentage' && this.entityData?.field_discount_type != 'flat'">
                                                ${{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}
                                            </ng-container></p>

                                        <div class="mb-1">
                                            <div class="mb-1">
                                                <strong>Taxes:</strong> {{ entityData?.field_tax_percentage ? this.entityData?.field_tax_percentage : '0' }}%
                                                <strong>APR:</strong> {{ entityData?.field_finance_apr ? this.entityData?.field_finance_apr : '0' }}%
                                            </div>
                                            <div class="mb-1">
                                                <strong>Total Tuition:</strong> ${{ entityData?.field_enrollment_total_price ? this.entityData?.field_enrollment_total_price : '0' }}
                                            </div>
                                        </div>

                                        <app-enrollment-payment-display
                                            [entityData]="entityData"
                                            [enrollmentForm]="this.enrollmentForm">
                                        </app-enrollment-payment-display>

                                        <p><strong>Finance Charge:</strong> {{ entityData?.field_finance_charge ? this.entityData?.field_finance_charge : '0' }}% <strong>Monthly Interest Rate</strong> {{ '0' }}%</p>

                                    </div>

                                    <div class="col-6">

                                        <p><strong>Enrollment Date:</strong> {{ this.entityData?.['field_sale_date'] | date }}</p>

                                        <p>
                                            <strong>Address:</strong>
                                            <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.address_line1">{{ this.studentAccountEntityData?.field_contacts?.field_address?.address_line1 }},<br /></span>
                                            <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.address_line2">{{ this.studentAccountEntityData?.field_contacts?.field_address?.address_line2 }}<br /></span>
                                            <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.locality">{{ this.studentAccountEntityData?.field_contacts?.field_address?.locality }},</span>
                                            <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area">{{ this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area }}</span>
                                            <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.postal_code">{{ this.studentAccountEntityData?.field_contacts?.field_address?.postal_code }}</span>
                                        </p>

                                        <p><strong>Email:</strong> {{ this.studentAccountEntityData?.field_contacts?.field_email }}</p>

                                        <p><strong>Notice to Buyer:</strong> Do not sign this agreement before you read it or if it contains
                                            any blank spaces. You are entitled to a copy of the agreement you sign. Keep
                                            this agreement to protect your legal rights.</p>

                                        <p><strong>If you Pay off early:</strong> You will not have to pay a penalty.
                                            You may be entitled to a refund or part of the finance charge, under rule of 78,
                                            pro-rata or actuarial method whichever is applicable in your state.</p>

                                        <p>It is agreed that the Studio's obligation for furnishing instructions under this agreement shall expire on {{this.entityData?.field_expiration_date }},
                                                or two years from the date of this agreement, whichever occurs first.</p>

                                    </div>

                                    <p class="text-center"><strong>STUDENT ACKNOWLEDGES RECEIPT OF AN EXACT COPY OF THIS RETAIL INSTALLMENT AGREEMENT.</strong></p>

                                    <p class="text-center display-none"><small><a class="cursor-pointer" (click)="printPage(true, false)">Click here to view back of agreement (fully included and opened when printed or emailed)</a></small></p>

                                </div>

                                <div class="row">
                                    <div class="col-6">
                                        <!-- Studio Information -->
                                        <div class="text-center">
                                            <p>{{ this.studioInformationConfig?.field_studio_display_name?.[0].value }}</p>
                                            <p>
                                                {{ this.studioInformationConfig?.field_studio_address?.[0]?.address_line1 }}
                                                {{ this.studioInformationConfig?.field_studio_address?.[0]?.locality }}
                                                {{ this.studioInformationConfig?.field_studio_address?.[0]?.administrative_area }}.
                                                {{ this.studioInformationConfig?.field_studio_address?.[0]?.postal_code }}
                                            </p>
                                            <p>Phone: {{ this.studioInformationConfig?.field_studio_phone_number?.[0]?.value }}</p>
                                            <p>Email: {{ this.studioInformationConfig?.field_studio_email?.[0]?.value }}</p>
                                            <p>{{ this.studioInformationConfig?.field_studio_website?.[0]?.title }}</p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <p>Signed on: {{ this.entityData?.['field_sale_date'] | date }}</p>

                                            <div *ngIf="this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']" class="col-6">
                                                <p><strong>Executive Approval:</strong></p>
                                                <img class="img-fluid" [src]="this._enrollmentEntityData?.['field_representative_signature'][0]?.['url']" />
                                            </div>
                                            <div *ngIf="this._enrollmentEntityData?.['field_signature']?.[0]?.['url']" class="col-6">
                                                <p><strong>Student Signature:</strong></p>
                                                <img class="img-fluid" [src]="this._enrollmentEntityData?.['field_signature']?.[0]?.['url']" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="enrollment-agreement-backpage" class="page2">
                                <ol id="l1"><li data-list-text="A."><p style="padding-top: 2pt;">The Studio agrees to provide this course of instruction and/or services in accordance
                                    with the Arthur Murray® method of dance instruction and to make its
                                    facilities and personnel available by individual independent appointment for
                                    each lesson or service for such purposes during the term of this agreement.
                                    The Studio may provide the Student with any instructor employed by the Studio
                                    and is not obligated to provide any specific instructor nor to provide the
                                    same instructor for different lessons.</p></li><li data-list-text="B."><p
                                    style="">Instructions shall be available commencing this date and shall not be
                                    charged against this enrollment until the completion of all previous
                                    enrollments, if any. All lessons are 45 minutes long, which includes
                                    transition time between lessons.</p></li><li data-list-text="C."><p
                                    style="">Personal lessons to be made available by the Studio, shall expire
                                    whether actually used or not upon the agreed expiration date for all
                                    instruction or lessons under this agreement. All group lessons (if charged)
                                    and Video Tape Studies shall expire over the same period as the private
                                    lessons. The teaching or honoring of any lessons and/or services beyond the
                                    term of expiration shall not be deemed as a waiver of this expiration
                                    provision by the Studio.</p></li><li data-list-text="D."><p
                                    style="">Student agrees to complete all lessons and/or services as expressly
                                    provided in this agreement. Student shall not be relieved of the obligation
                                    to make any payment agreed to, and no deduction or allowance for any payments
                                    shall be made by reason of Student&#39;s failure to use any lessons and/or
                                    services, except as provided.</p></li><li data-list-text="E."><p
                                    style="">This agreement is subject to cancellation at any time during the term
                                    of the Agreement upon notification by the Student. If this agreement is
                                    cancelled within three business days, the Studio will refund all payments
                                    made under the agreement. After three business days, the Studio will only
                                    charge you for the dance instructions and dance instruction services actually
                                    furnished under the agreement plus a reasonable and fair service fee.</p></li><li
                                data-list-text="F."><p style="">If other than an original
                                    enrollment, this agreement, if for dance instruction, is subject to
                                    cancellation by the Student without charge within seven days after the
                                    completion of the previous course of dance instruction. After the expiration
                                    of the term of this agreement, refunds will not be granted.</p></li><li
                                data-list-text="G."><p style="">&quot;Notice of cancellation&quot;
                                    shall be deemed to have been provided by a Student or prospective Student by
                                    mailing or delivering written notification to cancel the contract or written
                                    agreement; or by failing to attend instructional facilities for a period of
                                    five consecutive appointment days on which classes or the provision of
                                    services which are the subject of the contract or written agreement were
                                    prearranged with the Student or prospective Student.</p></li><li
                                data-list-text="H."><p style="">Unless otherwise stated in this
                                    agreement and for refund when applicable, there is no charge for providing
                                    group lessons, practice sessions, parties or complimentary services offered
                                    by the Studio and it is agreed that the tuition is based solely upon the
                                    number of personal lessons of instruction, the use of video equipment and
                                    expressly paid-for services.</p></li><li data-list-text="I."><p
                                    style="">The Studio
                                    may assign this agreement and all monies due shall be paid directly to such
                                    third party upon notification.</p></li><li data-list-text="J."><p
                                    style="">Student agrees to notify the Studio at least 12 hours in advance to
                                    cancel or change any private appointment or be charged for such lessons.</p></li><li
                                data-list-text="K."><p style="">Student&#39;s rights under this
                                    agreement are personal in nature and may not be sold, assigned or transferred
                                    to any other person. In the event of Student&#39;s death or physical
                                    disability, the Student or his representative may sell, donate or transfer
                                    the remaining lessons and/or services to any persons or charity subject to
                                    Studio approval. Student lessons may be transferred to any other Arthur
                                    Murray® Franchised Dance Studio beyond twenty-five miles from this Studio.</p></li><li
                                data-list-text="L."><p style="">Student agrees not to associate
                                    with any Studio instructor and other personnel outside the Studio or to give
                                    or loan anything of value to any Studio personnel during the term of this
                                    agreement and for a one year period thereafter.</p></li><li
                                data-list-text="M."><p style="">Student represents to the Studio
                                    that (s)he is physically able to take and financially able to pay for this
                                    course of instruction and/or services, has read and fully understands the
                                    terms of this agreement, has signed the agreement voluntarily and hereby
                                    acknowledges receipt of a fully executed copy.</p></li><li
                                data-list-text="N."><p style=""><strong>ANY HOLDER OF THIS CONSUMER CREDIT
                                    CONTRACT IS SUBJECT TO ALL CLAIMS AND DEFENSES WHICH THE DEBTOR COULD ASSERT
                                    AGAINST THE SELLER OF GOODS OR SERVICES OBTAINED PURSUANT HERETO OR WITH THE
                                    PROCEEDS HEREOF. RECOVERY HEREUNDER BY THE DEBTOR SHALL NOT EXCEED AMOUNTS
                                    PAID BY THE DEBTOR HEREUNDER.</strong></p></li><li data-list-text="O."><p
                                    style="">Any controversy or claim arising out of or relating to this agreement
                                    shall be settled solely by arbitration in accordance with the commercial
                                    arbitration rules of the American Arbitration Association, and judgment upon
                                    the award rendered by the arbitrator may be entered in any court having
                                    jurisdiction. All fees and expenses in connection with the arbitration shall
                                    be shared equally by the parties. Any action or arbitration on this agreement
                                    must be brought within 12 months from the date the cause of action arises or
                                    within six months from the expiration of this agreement, whichever occurs
                                    first.</p></li><li data-list-text="P."><p style="line-height: 107%;">The Federal
                                    Equal Credit Opportunity Act prohibits creditors from discriminating against
                                    credit applicants on the basis of sex or marital status. The Federal agency
                                    which administers compliance with this law is the F.T.C., Washington, D.C.</p></li><li
                                data-list-text="Q."><p style=""><strong>AS STUDENT, I UNDERSTAND AND AGREE
                                    THAT THIS AGREEMENT IS MADE BY ME SOLELY WITH THE OWNER OF THE STUDIO, AS
                                    SELLER, AND DOES NOT DIRECTLY OR INDIRECTLY CONSTITUTE AN AGREEMENT WITH OR
                                    AN OBLIGATION OF ARTHUR MURRAY INTERNATIONAL, INC., OR AS THE STUDIO
                                    FRANCHISOR. ARTHUR MURRAY INTERNATIONAL, INC. IS NOT THE OWNER OF THIS
                                    STUDIO. SHOULD THIS AGREEMENT COMBINED WITH THE COST OF STUDENT&#39;S OTHER
                                    UNUSED LESSONS AND/OR SERVICES, EXCEED $20,000.00 OR 200 ENROLLED PRIVATE
                                    LESSONS OR UNITS WHICHEVER OCCURS FIRST, OR THE MAXIMUM PERMITTED BY LAW,
                                    WHICHEVER IS LESS, THIS AGREEMENT IS VOID.</strong></p></li><li data-list-text="R."><p
                                    style="line-height: 11pt;">The term &quot;School&quot;, &quot;Studio&quot; or “Center” may be
                                    used for the same meaning, alternately or interchangeably.</p></li><li
                                data-list-text="S."><p style="">If any particular provision of this agreement is
                                    held invalid or unenforceable, same shall not affect the other provisions of
                                    this agreement.</p></li><li data-list-text="T."><p style="">No other representations or
                                    provisions, either written or oral, are a part of this agreement, unless
                                    expressed herein.</p></li></ol>
                            </div>
                        </div>

                        <div class="col-12">
                            <!-- <button (click)="openDrupalLink('/print/pdf/packages/' + this.entityData?.id)"  class="me-1" type="button" mat-stroked-button color="primary">
                                Print
                            </button> -->
                            <button (click)="printPage(false, true)"  class="me-1" type="button" mat-stroked-button color="primary">
                                Print
                            </button>
                            <!-- (click)="openDrupalLink('/amt_enrollment/send-enrollment-email/' + this.entityData?.id)" -->
                            <button class="me-1" type="button" mat-stroked-button color="primary" (click)="sendEmailNewEnrollment(this.enrollmentID)">
                                Email
                            </button>
                        </div>

                        <div class="mt-3"></div>
                    </div>
                </div>

            </mat-tab>

            <mat-tab [disabled]="this.initActionType != 'create'" label="{{(this.initActionType == 'create' ? 'Create' : 'Edit')}}">
                <mat-stepper [className]="this.initActionType == 'create' ? '' : 'hide-mat-horizontal-stepper-header-container'" [selectedIndex]="this.enrollmentStepperIndex" [linear]="true" #enrollmentStepper>
                    <mat-step [stepControl]="enrollmentForm">

                        <form *ngIf="enrollmentForm" [formGroup]="enrollmentForm" (ngSubmit)="onSubmitEnrollment(f, stepper)" #f="ngForm" class="form">
                            <ng-template matStepLabel>Enrollment</ng-template>


                            <div class="container">
                                <div class="row">
                                    <input class="d-none" formControlName="title" value="'default'" matInput>
                                    <input class="d-none" formControlName="field_payments_structure_type" value="field_payments_structure_type" matInput>

                                    <app-error-message [message]=this.errorMessage></app-error-message>
                                    <!-- Student Account Autocomplete -->
                                    <div class="col-6">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Student Account</mat-label>
                                            <input
                                                required
                                                name="field_student"
                                                (input)="onAutocompleteStudent($event);"
                                                matInput
                                                formControlName="field_student"
                                                required
                                                #td_student_account
                                                [matAutocomplete]="autoCompleteStudentAccount"
                                                [ngModel]="this._frmValues?.field_student"
                                                >
                                            <button type="button" matSuffix mat-icon-button
                                                aria-label="manage_accounts">
                                                <mat-icon>manage_accounts</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-autocomplete #autoCompleteStudentAccount="matAutocomplete" (optionSelected)="onAutoCompleteStudentLoadInstructorExecutive($event);checkStudentBalanceLimit(this.enrollmentForm.value.field_student);">
                                            <mat-option
                                                *ngFor="let option of autoCompleteStudentOptions"
                                                [value]="option.label + ' (' + option.value + ')'">
                                                {{option.label}} ({{option.value}})
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>

                                    <div class="col-6">
                                        <mat-form-field>
                                        <input
                                        formControlName="field_sale_date"
                                        name="field_sale_date"
                                        matInput
                                        (ngModelChange)="onEnrollmentSaleDate($event)"

                                        [ngxMatDatetimePicker]="picker_field_date_time_enrollment_form"
                                        placeholder="Enrollment Date"
                                        [ngModel]="this._frmValues?.field_sale_date"
                                        >
                                        <!-- {{this._frmValues?.field_sale_date}} -->
                                        <mat-datepicker-toggle matSuffix [for]="picker_field_date_time_enrollment_form">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker #picker_field_date_time_enrollment_form
                                            [hideTime]="true"
                                            [enableMeridian]="true"
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1" [stepMinute]="15"
                                            [touchUi]="false">
                                        </ngx-mat-datetime-picker>
                                        </mat-form-field>
                                    </div>

                                    <!-- Category Dropdown -->
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Category</mat-label>
                                            <mat-select (selectionChange)="calcNewTotal()" matInput formControlName="field_category" name="field_category">
                                                <mat-option>None</mat-option>
                                                <mat-option *ngFor="let element of this._taxonomyService.enrollment_type"
                                                    [value]="element.id">
                                                    {{ element.name }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Package Autocomplete -->
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                        <mat-label>Package</mat-label>
                                        <input formControlName="field_enrollment_package_name" name="field_enrollment_package_name" (input)="onAutocompletePackages($event);" matInput
                                            [matAutocomplete]="autoCompletePackage">
                                        <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                                            <mat-icon>manage_accounts</mat-icon>
                                        </button>
                                        </mat-form-field>
                                        <mat-autocomplete #autoCompletePackage="matAutocomplete" (optionSelected)="onPackageLookup($event);">
                                        <mat-option *ngFor="let option of autoCompletePackageOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
                                            {{option.label}} ({{option.value}})
                                        </mat-option>
                                        </mat-autocomplete>
                                    </div>

                                    <div class="container mb-2 d-none">
                                        <div class="row">
                                            <div style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius: 10px;">
                                                <h3>Gift Certificates</h3>
                                                <ng-container formArrayName="giftCertificates">
                                                    <div *ngFor="let giftCertificate of giftCertificatesArray.controls; let i = index" [formGroupName]="i">
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <mat-form-field class="full-width-field">
                                                                    <mat-label>Gift Certificate Code</mat-label>
                                                                    <input formControlName="code" matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-4">
                                                                <mat-form-field class="full-width-field">
                                                                    <mat-label>Redemption Amount</mat-label>
                                                                    <input type="number" formControlName="redemptionAmount" matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-2">
                                                                <button type="button" mat-icon-button color="primary" (click)="applyGiftCertificate(i)">
                                                                    <mat-icon>redeem</mat-icon>
                                                                </button>
                                                                <mat-icon *ngIf="giftCertificate.get('applied').value" style="color: green;">check_circle</mat-icon>
                                                            </div>
                                                            <div class="col-2">
                                                                <mat-icon
                                                                class="ms-2 cursor-pointer"
                                                                (click)="removeGiftCertificate(i)"
                                                                aria-hidden="false"
                                                                aria-label="trash">cancel</mat-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <button type="button" mat-raised-button color="primary" (click)="addGiftCertificate()">Add Gift Certificate</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <mat-form-field class="full-width-field">
                                                <mat-label>{{getAmountLabel()}}</mat-label>
                                                <input formControlName="field_enrollment_lesson_price" type="number" (change)="calcNewTotal();" name="field_enrollment_lesson_price" matInput>
                                                <span matPrefix>$&nbsp;</span>
                                            </mat-form-field>
                                        </div>

                                        <div [ngClass]="{ 'd-none': isMiscOrSundry  }" class="col-6">
                                            <mat-form-field class="full-width-field">
                                                <mat-label>Lesson Count</mat-label>
                                                <input min="0" type="number" (change)="calcNewTotal()" name="field_enrollment_lesson_count" matInput formControlName="field_enrollment_lesson_count">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <!-- Discount Type Dropdown -->
                                    <div class="col-3">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Discount Type</mat-label>
                                            <mat-select (ngModelChange)="calcNewTotal()"
                                             matInput
                                             [ngModel]="this._frmValues?.field_discount_type"
                                             formControlName="field_discount_type"
                                             name="field_discount_type">
                                                <mat-option>None</mat-option>
                                                <mat-option
                                                    (optionSelected)="calcNewTotal()"
                                                    *ngFor="let element of this._fieldsService.discountType"
                                                    [value]="element.key">
                                                    {{ element.value }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-3">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Discount</mat-label>
                                            <input [(ngModel)]="this.enrollmentForm.value.field_discount"
                                            min="0"
                                            type="number"
                                            (change)="calcNewTotal()"
                                            name="field_discount"
                                            matInput formControlName="field_discount">
                                            <span *ngIf="this.enrollmentForm.value.field_discount_type == 'flat'" matPrefix>$&nbsp;</span>
                                            <span *ngIf="this.enrollmentForm.value.field_discount_type == 'percentage'" matSuffix>&nbsp;%</span>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-2">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Taxes</mat-label>
                                            <input [ngModel]="this._frmValues?.field_tax_percentage" formControlName="field_tax_percentage" type="number" (change)="calcNewTotal()" name="field_tax_percentage" matInput>
                                            <span matSuffix>%&nbsp;</span>
                                        </mat-form-field>
                                    </div>

                                    <!-- This is for the entire enrollment. -->
                                    <input class="d-none" name="field_enrollment_total_price" matInput formControlName="field_enrollment_total_price">
                                    <div class="col-4 mb-1">
                                        <mat-form-field appearance="fill" class="full-width-field">
                                            <mat-label>Total Investment</mat-label>
                                            <input readonly name="field_total_price_with_est_tax" matInput formControlName="field_total_price_with_est_tax">
                                            <span matPrefix>$&nbsp;</span>
                                            <mat-hint>({{this.enrollmentForm.get("field_tax_percentage").value}}% estimated tax and ${{this.enrollmentForm.get("field_discount").value}} discount is calculated.)</mat-hint>
                                        </mat-form-field>
                                    </div>

                                    <div class="mt-3"></div>

                                    <div class="col-3 d-none">
                                        <mat-form-field appearance="fill" class="full-width-field">
                                            <mat-label>Total Lesson Price</mat-label>
                                            <input readonly name="field_total_lessons_price" matInput formControlName="field_total_lessons_price">
                                            <span matPrefix>$&nbsp;</span>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <!-- <div class="row">
                                    <div class="col-4">
                                        <mat-form-field appearance="fill" class="full-width-field">
                                            <mat-label>Total Paid</mat-label>
                                            <input readonly name="field_total_paid" matInput formControlName="field_total_paid">
                                        </mat-form-field>
                                    </div>
                                </div> -->
                            </div>

                            <div class="container">
                                <div class="row">

                                        <div class="col-6 d-none">
                                            <mat-form-field appearance="fill" class="full-width-field">
                                                <mat-label>Lesson Used</mat-label>
                                                <input readonly name="field_lesson_used" matInput formControlName="field_lesson_used">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6 d-none">
                                            <mat-form-field appearance="fill" class="full-width-field">
                                                <mat-label>Lesson Paid</mat-label>
                                                <input readonly name="field_lessons_paid" matInput formControlName="field_lessons_paid">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6 d-none">
                                            <mat-form-field appearance="fill" class="full-width-field">
                                                <mat-label>Lesson Available</mat-label>
                                                <input readonly name="field_lesson_available" matInput formControlName="field_lesson_available">
                                            </mat-form-field>
                                        </div>
                                </div>
                            </div>

                            <div *ngIf="studentBalanceWarning" class="col-12 mb-2 mt-2 text-center text-danger alert alert-warning">
                                <mat-badge *ngIf="studentBalanceWarning" color="warn" [matBadge]="'!'" matBadgeSize="small" matBadgeOverlap="false" class="balance-warning-badge">
                                    {{ studentBalanceWarning }}
                                </mat-badge>
                            </div>

                            <div class="container mb-2">
                                <div class="row">
                                    <div style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius: 10px;">
                                        <h3>Scheduled Payments</h3>
                                        <div class="row mb-3">
                                            <mat-radio-group [ngModel]="this._frmValues?.field_payments_structure" class="row" formControlName="field_payments_structure" aria-label="Select an option">
                                                <div class="col-3">
                                                    <mat-radio-button (click)="paid_in_full()" value="paid_in_full">Pay In Full</mat-radio-button>
                                                </div>
                                                <div class="col-3">
                                                    <mat-radio-button (click)="use_installments()" value="installments">Installments</mat-radio-button>
                                                </div>
                                            </mat-radio-group>

                                            <div *ngIf="is_use_installments && this.actionType == 'create'" class="d-inline mb-1 mt-1">
                                                <span class="me-1">
                                                    <button
                                                        type="button"
                                                        class="me-1"
                                                        (click)="enrollmentForm.get('field_payments_structure_type').setValue('weekly')"
                                                        mat-flat-button
                                                        [color]="enrollmentForm.get('field_payments_structure_type').value == 'weekly' ? 'accent' : 'basic'">Weekly</button>
                                                </span>
                                                <span class="me-1">
                                                    <button
                                                        type="button"
                                                        class="me-1"
                                                        (click)="enrollmentForm.get('field_payments_structure_type').setValue('monthly')"
                                                        mat-flat-button
                                                        [color]="enrollmentForm.get('field_payments_structure_type').value == 'monthly' ? 'accent' : 'basic'">Monthly</button>
                                                </span>
                                                <span class="me-1">
                                                    <button
                                                        type="button"
                                                        class="me-1"
                                                        (click)="enrollmentForm.get('field_payments_structure_type').setValue('by_the_lesson')"
                                                        mat-flat-button
                                                        [color]="enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson' ? 'accent' : 'basic'">By The Lesson</button>
                                                </span>
                                                <span>
                                                    <a mat-button class="ms-1" type="button" (click)="calcScheduledPayments()">Generate Payment Plan</a>
                                                </span>

                                                <div>
                                                    <ng-container [ngSwitch]="enrollmentForm.get('field_payments_structure_type').value">
                                                        <span *ngSwitchCase="'weekly'">Separate into</span>
                                                        <span *ngSwitchCase="'monthly'">Separate into</span>
                                                        <span *ngSwitchCase="'by_the_lesson'">Payments due every </span>
                                                        <span *ngSwitchDefault></span>
                                                    </ng-container>
                                                    <mat-form-field [ngClass]="{ 'd-none' : enrollmentForm.get('field_payments_structure_type').value == null}" style="width:50px" class="ms-1 me-1">
                                                        <input min="0" formControlName="interval" matInput type="number">
                                                    </mat-form-field>
                                                    <ng-container [ngSwitch]="enrollmentForm.get('field_payments_structure_type').value">
                                                        <span *ngSwitchCase="'weekly'">payments</span>
                                                        <span *ngSwitchCase="'monthly'">payments</span>
                                                        <span *ngSwitchCase="'by_the_lesson'">lessons</span>
                                                        <span *ngSwitchDefault></span>
                                                    </ng-container>
                                                    <span [ngClass]="{ 'd-none': interval != 'monthly' }">
                                                        payments months on the
                                                        <mat-form-field class="ms-1" style="width:180px">
                                                            <mat-label>Select Day</mat-label>
                                                            <mat-select
                                                            matInput
                                                            formControlName="field_recurrent_month_day">
                                                                <mat-option>None</mat-option>
                                                                <mat-option
                                                                    *ngFor="let element of this._fieldsService.dayDropdownSimple"
                                                                    [value]="element.value.toString()">
                                                                    {{ element.name }}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </span>
                                                    <span [ngClass]="">
                                                        <ng-container [ngSwitch]="enrollmentForm.get('field_payments_structure_type').value">
                                                            <span *ngSwitchCase="'weekly'"> starting on the</span>
                                                            <span *ngSwitchCase="'monthly'"> starting on the</span>
                                                            <span *ngSwitchCase="'by_the_lesson'">. Start on</span>
                                                            <span *ngSwitchDefault></span>
                                                        </ng-container>
                                                        <mat-form-field [ngClass]="{ 'd-none' : enrollmentForm.get('field_payments_structure_type').value == null}" class="ms-1" style="width:130px">
                                                            <mat-label>Select Day</mat-label>
                                                            <input
                                                            formControlName="field_recurrent_month_day"
                                                            matInput
                                                            [ngxMatDatetimePicker]="pickerRecurrentMonthDay"
                                                            placeholder="">
                                                            <mat-datepicker-toggle matSuffix [for]="pickerRecurrentMonthDay">
                                                            </mat-datepicker-toggle>
                                                            <ngx-mat-datetime-picker #pickerRecurrentMonthDay
                                                                [hideTime]="true"
                                                                [enableMeridian]="true"
                                                                [showSpinners]="true"
                                                                [showSeconds]="false"
                                                                [stepHour]="1" [stepMinute]="15"
                                                                [touchUi]="false">
                                                            </ngx-mat-datetime-picker>
                                                        </mat-form-field>
                                                        <mat-icon
                                                            [ngClass]="{
                                                                'd-none': !(enrollmentForm.get('field_payments_structure_type').value == 'monthly' && enrollmentForm.get('field_payments_structure_type').value != '')
                                                            }"
                                                            class="ms-2 cursor-pointer"
                                                            (click)="paymentPlanGeneratorIncreaseStep(i)"
                                                            aria-hidden="false"
                                                            aria-label="trash">add_box</mat-icon>
                                                    </span>
                                                </div>

                                                <div>
                                                    <ng-container *ngIf="enrollmentForm.get('payment_builder_date_step').controls">
                                                        <div formArrayName="payment_builder_date_step">
                                                            <div *ngFor="let instructorControl of enrollmentForm.get('payment_builder_date_step').controls; let i = index">
                                                                <div [formGroupName]="i" class="row">

                                                                    <div>
                                                                        <span [ngClass]="">
                                                                            Add additional payment on the
                                                                            <mat-form-field class="ms-1" style="width:125px">
                                                                                <mat-label>Select Day</mat-label>
                                                                                <mat-select
                                                                                matInput
                                                                                formControlName="field_recurrent_month_day">
                                                                                    <mat-option>None</mat-option>
                                                                                    <mat-option
                                                                                        *ngFor="let element of this._fieldsService.dayDropdown"
                                                                                        [value]="element.value">
                                                                                        {{ element.name }}</mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                            <mat-icon
                                                                                *ngIf="this.actionType == 'create'"
                                                                                class="ms-2 cursor-pointer"
                                                                                (click)="paymentPlanGeneratorRemoveStep(i)"
                                                                                aria-hidden="false"
                                                                                aria-label="trash">cancel</mat-icon>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <!-- Separate into
                                                <mat-form-field style="width:50px" class="ms-1 me-1">
                                                    <input min="0" formControlName="scheduled_payments_min_payment" matInput type="number">
                                                </mat-form-field> -->

                                                <div [ngClass]="{ 'd-none': enrollmentForm.get('field_payments_structure_type').value != 'weekly' }">
                                                    <div class="mt-1 mb-3">
                                                        <section class="example-section">
                                                            <!-- <mat-checkbox *ngFor="let day of daysOfWeek" ngModel name="{{day}}"
                                                                [value]="day"
                                                                class="example-margin">{{day}}</mat-checkbox> -->
                                                            <mat-checkbox formControlName="field_recurrent_weekday_su" name="field_recurrent_weekday_su" class="example-margin">Sun</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_mo" name="field_recurrent_weekday_mo" class="example-margin">Mon</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_tu" name="field_recurrent_weekday_tu" class="example-margin">Tue</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_we" name="field_recurrent_weekday_we" class="example-margin">Wed</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_th" name="field_recurrent_weekday_th" class="example-margin">Thu</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_fr" name="field_recurrent_weekday_fr" class="example-margin">Fri</mat-checkbox>
                                                            <mat-checkbox formControlName="field_recurrent_weekday_sa" name="field_recurrent_weekday_sa" class="example-margin">Sat</mat-checkbox>
                                                        </section>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <ng-container *ngIf="enrollmentForm.get('field_scheduled_payments').controls">
                                            <div formArrayName="field_scheduled_payments">
                                                <div *ngFor="let paymentControl of (enrollmentForm.get('field_scheduled_payments')).controls; let i = index">
                                                    <div [formGroupName]="i" class="row">

                                                        <mat-divider class="mb-2" *ngIf="i > 0"></mat-divider>

                                                        <input class="d-none" formControlName="id" value="id" matInput>
                                                        <input class="d-none" formControlName="type" name="type" value="scheduled_payments" matInput>
                                                        <input class="d-none" formControlName="bundle" name="bundle" value="scheduled_payments" matInput>
                                                        <input class="d-none" formControlName="field_studio_reference" name="field_studio_reference" value="field_studio_reference" matInput>

                                                        <div class="col-4">
                                                            <mat-form-field class="full-width-field">
                                                                <mat-label>Payment Amount</mat-label>
                                                                <input min="0" type="number" formControlName="field_payment_amount" name="percentage" matInput (input)="calculateTax(i)">
                                                                <span matPrefix>$&nbsp;</span>
                                                            </mat-form-field>
                                                        </div>

                                                        <div [ngClass]="{'col-2': enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson', 'col-3': enrollmentForm.get('field_payments_structure_type').value != 'by_the_lesson'}">
                                                            <mat-form-field class="full-width-field">
                                                              <mat-label>Tax Amount</mat-label>
                                                              <input type="text" formControlName="field_tax_amount" readonly matInput>
                                                              <span matPrefix>$&nbsp;</span>
                                                            </mat-form-field>
                                                          </div>

                                                        <div class="col-4"
                                                        [ngClass]="{
                                                            'd-none': (enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson')
                                                        }">
                                                            <mat-form-field>
                                                                <mat-label>Payment Date</mat-label>
                                                                <input
                                                                formControlName="field_payment_date"
                                                                name="field_payment_date"
                                                                matInput
                                                                [ngxMatDatetimePicker]="pickerFieldPaymentDate"
                                                                placeholder="">
                                                                <mat-datepicker-toggle matSuffix [for]="pickerFieldPaymentDate">
                                                                </mat-datepicker-toggle>
                                                                <ngx-mat-datetime-picker #pickerFieldPaymentDate
                                                                    [hideTime]="true"
                                                                    [enableMeridian]="true"
                                                                    [showSpinners]="true"
                                                                    [showSeconds]="false"
                                                                    [stepHour]="1" [stepMinute]="15"
                                                                    [touchUi]="false">
                                                                </ngx-mat-datetime-picker>
                                                            </mat-form-field>
                                                        </div>
                                                        <div [ngClass]="{
                                                            'd-none': enrollmentForm.get('field_payments_structure').value != 'installments'
                                                            || enrollmentForm.get('field_payments_structure_type').value != 'by_the_lesson'
                                                            || (this.initActionType != 'create' && this.initActionType != 'edit' && this.initActionType != 'editEnrollment')
                                                            }" class="col-2">
                                                            <mat-form-field class="full-width-field">
                                                                <mat-label>Due At</mat-label>
                                                                <input min="0" type="number" formControlName="field_number_lesson_remaining" name="field_number_lesson_remaining" matInput>
                                                                <span matPrefix>#&nbsp;</span>
                                                            </mat-form-field>
                                                        </div>

                                                        <div class="col-4 d-none">
                                                            <mat-form-field class="full-width-field">
                                                                <mat-label>Amount Paid</mat-label>
                                                                <input min="0" type="number" formControlName="field_amount_paid" name="field_amount_paid" matInput>
                                                                <span matPrefix>$&nbsp;</span>
                                                            </mat-form-field>
                                                        </div>

                                                        <div class="col-3 d-none">
                                                            <mat-checkbox formControlName="field_paid_in_full" name="field_paid_in_full" class="example-margin">Paid In Full</mat-checkbox>
                                                        </div>

                                                        <!-- <div class="col-3">
                                                            <mat-checkbox [ngClass]="{
                                                                'd-none': i > 0
                                                            }" formControlName="field_down_payment" name="field_down_payment" class="example-margin">Initial <br />Investment</mat-checkbox>
                                                        </div> -->

                                                        <div class="col-1">
                                                            <div>
                                                                <mat-icon
                                                                    *ngIf="this.actionType == 'create'"
                                                                    class="cursor-pointer"
                                                                    (click)="scheduledPaymentRemove(i)"
                                                                    aria-hidden="false"
                                                                    aria-label="trash">cancel</mat-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- [disabled]="!enrollmentForm.get('field_scheduled_payments').valid" -->
                                        <button *ngIf="!is_pay_in_full && this.actionType == 'create'" type="button"
                                            mat-raised-button
                                            class="me-1"
                                            color="primary" (click)="scheduledPaymentIncrement()">
                                            Add Scheduled Payment
                                        </button>
                                    </div>
                                </div>
                            </div>



                                <div class="container">
                                    <div class="row">

                                    <!-- <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Expiration Date</mat-label>
                                            <input
                                                matInput
                                                [matDatepicker]="pickerFieldExpirationDate"
                                                required
                                                name="field_expiration_date"
                                                formControlName="field_expiration_date"
                                                >
                                            <mat-datepicker-toggle matSuffix [for]="pickerFieldExpirationDate"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerFieldExpirationDate></mat-datepicker>
                                        </mat-form-field>
                                    </div> -->

                                    <div class="col-12">
                                        <mat-form-field>
                                        <mat-label>Expiration Date</mat-label>
                                        <input
                                        formControlName="field_expiration_date"
                                        name="field_expiration_date"
                                        matInput
                                        [ngxMatDatetimePicker]="pickerFieldExpirationDate"
                                        placeholder="">
                                        <mat-datepicker-toggle matSuffix [for]="pickerFieldExpirationDate">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker #pickerFieldExpirationDate
                                            [hideTime]="true"
                                            [enableMeridian]="true"
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1" [stepMinute]="15"
                                            [touchUi]="false">
                                        </ngx-mat-datetime-picker>
                                        </mat-form-field>
                                    </div>

                                    <!-- {{this.enrollmentForm.value | json}} -->

                                    <!-- Executive Dropdown -->
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Executive</mat-label>
                                            <mat-select required matInput formControlName="field_executive" name="field_executive">
                                                <mat-option>None</mat-option>
                                                <ng-container *ngFor="let element of _fieldsService.instructorData(); let i = index">
                                                    <mat-option *ngIf="element.status !== '0' || (element.status === '0' && element.uid === field_executive)"
                                                                [value]="element.uid">
                                                        {{ element.value }}
                                                        <ng-container *ngIf="element.status === '0' && element.uid === field_executive"></ng-container>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <!-- Junior Executive Dropdown -->
                                    <!-- <div class="col-4">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Junior Executive</mat-label>
                                            <mat-select required matInput formControlName="field_junior_executive" name="field_junior_executive">
                                                <mat-option>None</mat-option>
                                                <mat-option *ngFor="let element of this._fieldsService.instructorsJuniorExecutive();"
                                                    [value]="element.uid">
                                                    {{ element.value }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->

                                    <!-- Instructor List -->
                                    <!-- <app-display-instructors
                                    [field_students]="field_student"
                                    [studentUpdateLoad]="studentUpdateLoad"
                                    [studentRemove]="studentRemove"></app-display-instructors> -->

                                    <div formArrayName="field_instructor_percentages" style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius: 10px;">
                                        <h3>Instructor Percentage</h3>
                                        <!-- {{this._frmValues?.field_instructor_percentages | json}} -->
                                        <ng-container *ngIf="enrollmentForm.get('field_instructor_percentages').controls">
                                            <div *ngFor="let instructorControl of (enrollmentForm.get('field_instructor_percentages'))?.controls; let i = index">
                                                <div [formGroupName]="i" class="row">

                                                    <input class="d-none" formControlName="id" value="id" matInput>
                                                    <input class="d-none" formControlName="type" value="instructor_percentages" matInput>
                                                    <input class="d-none" formControlName="bundle" value="instructor_percentages" matInput>

                                                    <div class="col-8">
                                                        <mat-form-field class="full-width-field">
                                                            <mat-label>Instructor</mat-label>
                                                            <mat-select required matInput formControlName="field_instructor" name="instructor">
                                                                <mat-option>None</mat-option>
                                                                <ng-container *ngFor="let element of _fieldsService.instructorData(); let i = index">
                                                                    <mat-option *ngIf="element.status !== '0' || (element.status === '0' && element.uid === field_instructor)"
                                                                                [value]="element.uid">
                                                                        {{ element.value }}
                                                                        <ng-container *ngIf="element.status === '0' && element.uid === field_instructor"></ng-container>
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-3">
                                                        <mat-form-field class="full-width-field">
                                                            <mat-label>Percentage</mat-label>
                                                            <input (ngModelChange)="fromPercentCalcTotal()" min="0" max="100" type="number" formControlName="field_percentage" name="field_percentage" matInput>
                                                            <span matSuffix>&nbsp;%</span>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-3 d-none">
                                                        <mat-form-field class="full-width-field">
                                                            <mat-label>Amount</mat-label>
                                                            <input min="0" type="number" formControlName="field_amount" name="field_amount" matInput>
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="col-1">
                                                        <div>
                                                            <mat-icon
                                                                class="me-2 mt-2"
                                                                *ngIf="this.actionType == 'create'"
                                                                (click)="instructorPercentageRemove(i)"
                                                                aria-hidden="false"
                                                                aria-label="trash">cancel</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <!-- [disabled]="!enrollmentForm.get('field_instructor_percentages').valid" -->
                                        <button *ngIf="!instructorPercentageEditMode" type="button"
                                            mat-raised-button
                                            class="me-1"
                                            color="primary" (click)="instructorPercentageIncrement()">
                                            Add Instructor Percentage
                                        </button>

                                    </div>
                                    <div *ngIf="actionType === 'create' && enrollmentForm.get('field_instructor_percentages').value.length === 0" class="text-danger">
                                        * Please add at least one instructor percentage.
                                    </div>

                                    <div class="col-12">
                                        <button *ngIf="instructorPercentageEditMode" type="button"
                                            mat-raised-button
                                            [disabled]="!enrollmentForm.get('field_instructor_percentages').valid"
                                            class="me-1"
                                            color="primary" (click)="studentUpdateSubmit(i, instructor.value)">
                                            Edit Instructor Percentage
                                        </button>
                                    </div>

                                    <div class="col-12 mt-1">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Notes</mat-label>
                                            <textarea formControlName="field_notes" [ngModel]="this._frmValues?.field_notes" name="field_notes"
                                                matInput placeholder=""></textarea>
                                        </mat-form-field>
                                    </div>

                                    <!-- <div class="col-6">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Interval Unit </mat-label>
                                            <mat-select matInput formControlName="field_interval_unit" name="field_interval_unit">
                                                <mat-option>None</mat-option>
                                                <mat-option value="Day">Days</mat-option>
                                                <mat-option value="Weeks">Weeks</mat-option>
                                                <mat-option value="Months">Months</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->

                                    <!-- <div class="col-6">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Enrollment Status</mat-label>
                                            <mat-select matInput formControlName="field_enrollment_status" name="field_enrollment_status">
                                                <mat-option value="_none">None</mat-option>
                                                <mat-option value="Open">Open</mat-option>
                                                <mat-option value="Close">Closed</mat-option>
                                                <mat-option value="Dropped">Dropped</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div> -->

                                    <!-- <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Next Scheduled Payment</mat-label>
                                            <input
                                            matInput
                                            [matDatepicker]="pickerFieldNextScheduledPayment"
                                            name="field_next_scheduled_payment"
                                            formControlName="field_next_scheduled_payment"
                                            >
                                            <mat-datepicker-toggle matSuffix [for]="pickerFieldNextScheduledPayment"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerFieldNextScheduledPayment></mat-datepicker>
                                        </mat-form-field>
                                    </div> -->

                                    <div class="col-12 d-none">
                                        <mat-checkbox
                                        formControlName="field_visibility"
                                        name="field_visibility"
                                        class="">Hidden</mat-checkbox>

                                        <mat-checkbox
                                        formControlName="field_draft"
                                        name="field_draft"
                                        class="">Draft</mat-checkbox>

                                    </div>

                                    <div class="mt-4">
                                      <!-- Button for creating enrollment. -->
                                      <button
                                      *ngIf="this.initActionType == 'create'"
                                      [disabled]="!enrollmentForm.valid || !enrollmentForm.get('field_instructor_percentages').value.length > 0 || studentBalanceWarning" (click)="onSubmitEnrollment(f, 'create')" type="button" mat-button>Next</button>

                                      <!-- Button for editing enrollment. -->
                                      <button
                                      *ngIf="this.initActionType == 'editEnrollment'"
                                       (click)="onSubmitEnrollment(f, 'edit')" type="button" mat-button>Save</button>
                                    </div>


                                    <!-- <p>Form value: {{ enrollmentForm.value | json }}</p>
                                    <p>Form status: {{ enrollmentForm.status | json }}</p>

                                    {{getFormErrors(this.enrollmentForm) | json}} -->

                                </div>
                            </div>
                        </form>
                    </mat-step>

                    <mat-step [stepControl]="agreementFormGroup" label="Agreement">
                      <form [formGroup]="agreementFormGroup">

                        <input class="d-none" formControlName="field_nothing">
                        <!-- <input formControlName="firstName"> -->

                        <p class="text-center"><img style="max-width:350px;" src="assets/images/AMDCBlackHorizontalJPG.jpg" /></p>

                        <div id="enrollment-agreement-new">
                            <h3>{{ this.entityData?.title }}</h3>

                            <div class="row">
                                <div class="col-12">

                                    <p><strong>Student Name:</strong> {{ entityData?.['field_student']?.['title'] }} ({{ entityData?.['field_student']?.['id'] }})</p>

                                    <p><strong>Personal Lessons:</strong> {{ entityData?.['field_enrollment_lesson_count'] }}</p>

                                    <p><strong>Package Cost:</strong> ${{ getOriginalPackageCost() }}</p>

                                    <p><strong>Discount:</strong>
                                        <ng-container *ngIf="this.entityData?.field_discount_type == 'flat'">
                                            ${{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}
                                        </ng-container>
                                        <ng-container *ngIf="this.entityData?.field_discount_type == 'percentage'">
                                            {{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}%
                                        </ng-container>
                                        <ng-container *ngIf="this.entityData?.field_discount_type != 'percentage' && this.entityData?.field_discount_type != 'flat'">
                                            0%
                                        </ng-container>
                                    </p>

                                    <p><strong>Estimated Tax Rate:</strong>
                                        {{ entityData?.field_tax_percentage ? this.entityData?.field_tax_percentage : '0' }}%
                                        (${{ (getOriginalPackageCost() - entityData?.field_discount) * (entityData?.field_tax_percentage / 100) | number:'1.2-2' }})
                                    </p>

                                    <p><strong>Finance Charge:</strong> {{ entityData?.field_finance_charge ? this.entityData?.field_finance_charge : '0' }}%</p>

                                    <p><strong>APR:</strong> {{ entityData?.field_finance_apr ? this.entityData?.field_finance_apr : '0' }}%</p>

                                    <p><strong>Total Tuition (including estimated tax):</strong>
                                        ${{ calculateTotalTuition() | number:'1.2-2' }}
                                    </p>

                                    <app-enrollment-payment-display
                                        [entityData]="entityData"
                                        [enrollmentForm]="this.enrollmentForm">
                                    </app-enrollment-payment-display>

                                    <p><strong>Enrollment Date</strong></p>
                                    <p>{{ this.entityData?.['field_sale_date'] | date }}</p>

                                    <!-- <p> -->
                                        <!-- Address:<br /> -->
                                        <!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.address_line1">{{ this.studentAccountEntityData?.field_contacts?.field_address?.address_line1 }},<br /></span> -->
                                        <!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.address_line2">{{ this.studentAccountEntityData?.field_contacts?.field_address?.address_line2 }}<br /></span> -->
                                        <!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.locality">{{ this.studentAccountEntityData?.field_contacts?.field_address?.locality }},</span> -->
                                        <!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area">{{ this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area }}</span> -->
                                        <!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.postal_code">{{ this.studentAccountEntityData?.field_contacts?.field_address?.postal_code }}</span> -->
                                    <!-- </p> -->

                                    <!-- <p>
                                        Email: {{ this.studentAccountEntityData?.field_contacts?.field_email }}
                                    </p> -->
                                    <!-- <p>
                                        Date:
                                    </p> -->
                                    <!-- <p>
                                        Phone: {{ this.studentAccountEntityData?.field_contacts?.field_cell_phone }}
                                    </p> -->
                                </div>

                                <div class="col-6">
                                </div>
                            </div>

                            <p>
                                Notice to Buyer: Do not sign this agreement before you read it or if it contains any blank spaces. You are entitled to a copy of the agreement you sign. Keep this agreement to protect your legal rights. You WILL NOT have to pay a penalty if paid off early. You MAY be entitled to a refund or part of the finance charge, under rule 78, pro‐rata or actuarial method whichever is applicable in your state.
                            </p>

                            <p>
                                It is agreed that the Studio’s obligation for furnishing instruction under this agreement shall expire on
                                <strong>{{ this.entityData?.field_expiration_date | date }}</strong>
                                , or two years from the date of this agreement whichever occurs first.
                            </p>

                            <p class="text-center">
                                <small><a class="cursor-pointer display-none" (click)="printPage(true, false)">Click here to view back of agreement (fully included and opened when printed or emailed)</a></small>
                            </p>

                            <div class="row pb-3 pt-3">
                                <div class="col-6">
                                    Studio Representative:
                                    <canvas width="250px" height="125px" class="signature-canvas" #SignatureStudio>

                                    </canvas>
                                    <button class="mt-1 display-none" type="button" mat-stroked-button (click)="signatureClearStudio()">Clear</button>
                                </div>

                                <div class="col-6">
                                    Student Approval:
                                    <canvas width="250px" height="125px" class="signature-canvas" #SignatureStudent>

                                    </canvas>
                                    <button class="mt-1 display-none" type="button" mat-stroked-button (click)="signatureClearStudent()">Clear</button>
                                </div>
                            </div>
                            <div id="enrollment-agreement-backpage" class="page2">
                                <br />
                                <br />
                                <br />
                                <ol id="l1"><li data-list-text="A."><p style="padding-top: 2pt;">The Studio agrees to provide this course of instruction and/or services in accordance
                                    with the Arthur Murray® method of dance instruction and to make its
                                    facilities and personnel available by individual independent appointment for
                                    each lesson or service for such purposes during the term of this agreement.
                                    The Studio may provide the Student with any instructor employed by the Studio
                                    and is not obligated to provide any specific instructor nor to provide the
                                    same instructor for different lessons.</p></li><li data-list-text="B."><p
                                    style="">Instructions shall be available commencing this date and shall not be
                                    charged against this enrollment until the completion of all previous
                                    enrollments, if any. All lessons are 45 minutes long, which includes
                                    transition time between lessons.</p></li><li data-list-text="C."><p
                                    style="">Personal lessons to be made available by the Studio, shall expire
                                    whether actually used or not upon the agreed expiration date for all
                                    instruction or lessons under this agreement. All group lessons (if charged)
                                    and Video Tape Studies shall expire over the same period as the private
                                    lessons. The teaching or honoring of any lessons and/or services beyond the
                                    term of expiration shall not be deemed as a waiver of this expiration
                                    provision by the Studio.</p></li><li data-list-text="D."><p
                                    style="">Student agrees to complete all lessons and/or services as expressly
                                    provided in this agreement. Student shall not be relieved of the obligation
                                    to make any payment agreed to, and no deduction or allowance for any payments
                                    shall be made by reason of Student&#39;s failure to use any lessons and/or
                                    services, except as provided.</p></li><li data-list-text="E."><p
                                    style="">This agreement is subject to cancellation at any time during the term
                                    of the Agreement upon notification by the Student. If this agreement is
                                    cancelled within three business days, the Studio will refund all payments
                                    made under the agreement. After three business days, the Studio will only
                                    charge you for the dance instructions and dance instruction services actually
                                    furnished under the agreement plus a reasonable and fair service fee.</p></li><li
                                data-list-text="F."><p style="">If other than an original
                                    enrollment, this agreement, if for dance instruction, is subject to
                                    cancellation by the Student without charge within seven days after the
                                    completion of the previous course of dance instruction. After the expiration
                                    of the term of this agreement, refunds will not be granted.</p></li><li
                                data-list-text="G."><p style="">&quot;Notice of cancellation&quot;
                                    shall be deemed to have been provided by a Student or prospective Student by
                                    mailing or delivering written notification to cancel the contract or written
                                    agreement; or by failing to attend instructional facilities for a period of
                                    five consecutive appointment days on which classes or the provision of
                                    services which are the subject of the contract or written agreement were
                                    prearranged with the Student or prospective Student.</p></li><li
                                data-list-text="H."><p style="">Unless otherwise stated in this
                                    agreement and for refund when applicable, there is no charge for providing
                                    group lessons, practice sessions, parties or complimentary services offered
                                    by the Studio and it is agreed that the tuition is based solely upon the
                                    number of personal lessons of instruction, the use of video equipment and
                                    expressly paid-for services.</p></li><li data-list-text="I."><p
                                    style="">The Studio
                                    may assign this agreement and all monies due shall be paid directly to such
                                    third party upon notification.</p></li><li data-list-text="J."><p
                                    style="">Student agrees to notify the Studio at least 12 hours in advance to
                                    cancel or change any private appointment or be charged for such lessons.</p></li><li
                                data-list-text="K."><p style="">Student&#39;s rights under this
                                    agreement are personal in nature and may not be sold, assigned or transferred
                                    to any other person. In the event of Student&#39;s death or physical
                                    disability, the Student or his representative may sell, donate or transfer
                                    the remaining lessons and/or services to any persons or charity subject to
                                    Studio approval. Student lessons may be transferred to any other Arthur
                                    Murray® Franchised Dance Studio beyond twenty-five miles from this Studio.</p></li><li
                                data-list-text="L."><p style="">Student agrees not to associate
                                    with any Studio instructor and other personnel outside the Studio or to give
                                    or loan anything of value to any Studio personnel during the term of this
                                    agreement and for a one year period thereafter.</p></li><li
                                data-list-text="M."><p style="">Student represents to the Studio
                                    that (s)he is physically able to take and financially able to pay for this
                                    course of instruction and/or services, has read and fully understands the
                                    terms of this agreement, has signed the agreement voluntarily and hereby
                                    acknowledges receipt of a fully executed copy.</p></li><li
                                data-list-text="N."><p style=""><strong>ANY HOLDER OF THIS CONSUMER CREDIT
                                    CONTRACT IS SUBJECT TO ALL CLAIMS AND DEFENSES WHICH THE DEBTOR COULD ASSERT
                                    AGAINST THE SELLER OF GOODS OR SERVICES OBTAINED PURSUANT HERETO OR WITH THE
                                    PROCEEDS HEREOF. RECOVERY HEREUNDER BY THE DEBTOR SHALL NOT EXCEED AMOUNTS
                                    PAID BY THE DEBTOR HEREUNDER.</strong></p></li><li data-list-text="O."><p
                                    style="">Any controversy or claim arising out of or relating to this agreement
                                    shall be settled solely by arbitration in accordance with the commercial
                                    arbitration rules of the American Arbitration Association, and judgment upon
                                    the award rendered by the arbitrator may be entered in any court having
                                    jurisdiction. All fees and expenses in connection with the arbitration shall
                                    be shared equally by the parties. Any action or arbitration on this agreement
                                    must be brought within 12 months from the date the cause of action arises or
                                    within six months from the expiration of this agreement, whichever occurs
                                    first.</p></li><li data-list-text="P."><p style="line-height: 107%;">The Federal
                                    Equal Credit Opportunity Act prohibits creditors from discriminating against
                                    credit applicants on the basis of sex or marital status. The Federal agency
                                    which administers compliance with this law is the F.T.C., Washington, D.C.</p></li><li
                                data-list-text="Q."><p style=""><strong>AS STUDENT, I UNDERSTAND AND AGREE
                                    THAT THIS AGREEMENT IS MADE BY ME SOLELY WITH THE OWNER OF THE STUDIO, AS
                                    SELLER, AND DOES NOT DIRECTLY OR INDIRECTLY CONSTITUTE AN AGREEMENT WITH OR
                                    AN OBLIGATION OF ARTHUR MURRAY INTERNATIONAL, INC., OR AS THE STUDIO
                                    FRANCHISOR. ARTHUR MURRAY INTERNATIONAL, INC. IS NOT THE OWNER OF THIS
                                    STUDIO. SHOULD THIS AGREEMENT COMBINED WITH THE COST OF STUDENT&#39;S OTHER
                                    UNUSED LESSONS AND/OR SERVICES, EXCEED $20,000.00 OR 200 ENROLLED PRIVATE
                                    LESSONS OR UNITS WHICHEVER OCCURS FIRST, OR THE MAXIMUM PERMITTED BY LAW,
                                    WHICHEVER IS LESS, THIS AGREEMENT IS VOID.</strong></p></li><li data-list-text="R."><p
                                    style="line-height: 11pt;">The term &quot;School&quot;, &quot;Studio&quot; or “Center” may be
                                    used for the same meaning, alternately or interchangeably.</p></li><li
                                data-list-text="S."><p style="">If any particular provision of this agreement is
                                    held invalid or unenforceable, same shall not affect the other provisions of
                                    this agreement.</p></li><li data-list-text="T."><p style="">No other representations or
                                    provisions, either written or oral, are a part of this agreement, unless
                                    expressed herein.</p></li></ol>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-6">
                                <button (click)="printPage(false, true)"  class="me-1" type="button" mat-stroked-button color="primary">
                                    Print
                                </button>
                                <button class="me-1" type="button" mat-stroked-button color="primary" (click)="sendEmailNewEnrollment(this.entityData?.id)">
                                    Email
                                </button>
                            </div>
                            <div class="col-6">
                                <div class="pull-end">
                                    <button
                                    [disabled]="!detectSignatureStudio || !detectSignatureStudent"
                                    class="me-1"
                                    type="button"
                                    mat-stroked-button
                                    (click)="onSubmitSignatures('save_and_add')"
                                    color="primary">
                                        Save and Add
                                    </button>
                                    <button
                                    [disabled]="!detectSignatureStudio || !detectSignatureStudent"
                                    class="me-1"
                                    type="button"
                                    mat-stroked-button
                                    (click)="onSubmitSignatures('save_and_pay')"
                                    color="primary">
                                        Save and Pay
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="mt-3">
                          <button class="me-1" mat-button matStepperPrevious>Back</button>
                          <button mat-button matStepperNext>Next</button>
                        </div> -->
                      </form>
                    </mat-step>


                    <mat-step [stepControl]="paymentForm" label="Payment">
                        <app-payments-entity-form
                        [enrollmentData]="enrollmentEntityData"
                        [studentData]="studentAccountEntityData"
                        (paymentSubmitted)="onPaymentSubmitted($event)"
                        [isNested]="true">
                      </app-payments-entity-form>
                      </mat-step>

                    <mat-step>
                      <ng-template matStepLabel>Done</ng-template>
                      <p>You are now done.</p>
                      <div>
                        <!-- 'field_student_account': this.enrollmentForm?.value?.field_student, -->
                        <button
                            class="me-1"
                            mat-button
                            (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create', '', {
                                'field_type': getFieldTypeBasedOnDepartment(this.enrollmentEntityData?.field_student?.field_student_department),
                                'field_student': [
                                    {
                                        'type': 'attendees',
                                        'title': this.enrollmentEntityData?.field_student?.title,
                                        'field_enrollment': this.enrollmentEntityData?.id,
                                        'field_status': '64',
                                        'field_students': this.enrollmentEntityData?.field_student?.field_contacts,
                                        'field_student_account': this.enrollmentEntityData?.field_student?.title + ' (' + this.enrollmentEntityData?.field_student?.id + ')',
                                        'bundle': 'attendance'
                                    },
                                ],
                                'field_instructor': this.enrollmentForm?.value?.field_instructor_percentages?.[0]?.field_instructor
                            })">
                            Schedule Appointment
                        </button>
                        <button mat-button (click)="this._dialog.closeAll(); openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'create', null)">Add Enrollment</button>
                      </div>
                    </mat-step>
                </mat-stepper>

            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'" *ngIf="this.actionType != 'create'">
                <app-error-message [message]=this.errorMessage></app-error-message>
                <app-entity-confirm-delete></app-entity-confirm-delete>
                <app-enrollment-lesson-list-view (dataCountEvent)='updateCount($event)' [enrollmentID]="this.enrollmentID"></app-enrollment-lesson-list-view>
            </mat-tab>

        </mat-tab-group>

    </div>
</div>

<div>
    <!-- <p> -->
    <!-- {{findInvalidControlsRecursive(this?.enrollmentForm) | json}} -->
    <!-- </p> -->
    <!-- {{this?.enrollmentForm?.value | json}} -->
    <!-- enrollmentForm.valid: {{enrollmentForm?.valid}} -->
    <!-- get why the form isn't valid. -->
    <!-- enrollmentForm.errors: {{enrollmentForm?.errors | json}} -->
</div>

<mat-dialog-actions
    [ngClass]="{
        'd-none': !(this.actionType === 'delete' || this.actionType === 'edit')
    }"
    align="end">
    <ng-container *ngIf="this.actionType === 'create'">
        <!-- <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button> -->
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType === 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="closeDialog();" type="submit" mat-raised-button color="accent">Cancel</button>
        <button [disabled]="this.eventCount > 0" (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete Enrollment</button>
    </ng-container>
</mat-dialog-actions>
