import { ComponentType } from '@angular/cdk/overlay';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Params } from '@angular/router';
import { PaidLessonAuditComponent } from '../../audit/paid-lesson-audit/paid-lesson-audit.component';
import { BaseDialogComponent } from '../../forms/base-dialog/base-dialog.component';
import { EnrollmentEntityComponent } from '../../forms/enrollment-entity/enrollment-entity.component';
import { EventLessonEntityComponent } from '../../forms/event-lesson-entity/event-lesson-entity.component';
import { EventServicesEntityComponent } from '../../forms/event-services-entity/event-services-entity.component';
import { InquiryComponent } from '../../forms/inquiry/inquiry.component';
import { SetOpeningBalanceComponent } from '../../forms/set-opening-balance/set-opening-balance.component';
import { StudentDepartmentComponent } from '../../forms/student-department/student-department.component';
import { StudentInquiryDetailsComponent } from '../../forms/student-inquiry-details/student-inquiry-details.component';
import { StudentInquiryEntityComponent } from '../../forms/student-inquiry-entity/student-inquiry-entity.component';
import { StudentRecordEntityComponent } from '../../forms/student-record-entity/student-record-entity.component';
import { StudentDashboardComponent } from '../student-dashboard/student-dashboard.component';
import tippy from 'tippy.js';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-student-dashboard-wrapper',
  templateUrl: './student-dashboard-wrapper.component.html',
  styleUrls: ['./student-dashboard-wrapper.component.css']
})
export class StudentDashboardWrapperComponent extends BaseDialogComponent {

  // Modals to load.
  InquiryComponent = InquiryComponent;
  EventServicesEntityComponent = EventServicesEntityComponent;
  EventLessonEntityComponent = EventLessonEntityComponent;
  StudentInquiryEntityComponent = StudentInquiryEntityComponent;
  EnrollmentEntityComponent = EnrollmentEntityComponent;
  StudentRecordEntityComponent = StudentRecordEntityComponent;
  SetOpeningBalanceComponent = SetOpeningBalanceComponent;
  StudentDepartmentComponent = StudentDepartmentComponent;
  StudentInquiryDetailsComponent = StudentInquiryDetailsComponent;
  PaidLessonAuditComponent = PaidLessonAuditComponent;

  routeParams: any = {};
  sortParams: any = {};

  lessonStatuses: any[] = [];
  enrollmentBalance: any;

  user: { id: number }
  studentDashboardData: any;
  studentServices: any;
  studentServicesColumns: any;
  studentServicesRows: any;
  lessonListRows: any;
  lessonList2Rows: any;
  lessonListColumns: string[] = ['date', 'service', 'length', 'teacher', 'status', 'contacts_present', 'taken / rem', 'edit'];
  lessonList: any;
  studentAccountID;
  studentDashboardObservable;
  dataSource: Object;
  currentEnrollment: any;
  overallEnrollmentBalance: any;
  nextLessonScheduled: any;
  studentBadgeInfo: any;
  private tippyInstances: any[] = [];
  private subscriptions: Subscription[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paymentReportColumns: string[];
  selectedTab: number = 0;


  ngOnDestroy(): void {
    this.destroyTippyInstances(); // Ensure Tippy instances are cleaned up
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  destroyTippyInstances(): void {
    // Iterate over all stored instances and destroy them
    this.tippyInstances.forEach(instance => {
      if (instance && instance.destroy) {
        instance.destroy();
      }
    });

    // Clear the array after destroying the instances
    this.tippyInstances = [];
  }

  override ngAfterViewInit() {
    // this.lessonListRows.paginator = this.paginator;
    // this.lessonListRows.sort = this.sort;
  }

  override ngOnInit(): void {
    this.showProgressSpinner = true;

    this.user = {
      id: this._activatedRoute.snapshot.params['id'] ?? this?._injectedDialogData?.['data']?.['student_id'] ?? '',
    }

    this.getStudentDashboard(this?._injectedDialogData?.['data']?.['student_id']);

    this.getLessonList();

    this.lessonStatuses = this._taxonomyService.lesson_status;
  }


  getLessonList() {
    this._drupalRESTService.httpGET('/api_rest/v1/getLessonList', [
      { parameter: 'id', value: this.user.id.toString() }
    ]).subscribe(
      (data: any) => {
        this.lessonListColumns = ['date', 'service', 'length', 'teacher', 'status', 'contacts_present', 'taken / rem', 'edit'];
        this.lessonListRows = new MatTableDataSource(data?.['#student']?.['#rows']?.reverse());
        this.lessonListRows.paginator = this.paginator;
        this.lessonListRows.sort = this.sort;

        this.lessonList2Rows = new MatTableDataSource(data.rows.reverse());
        this.lessonList2Rows.paginator = this.paginator;
        this.lessonList2Rows.sort = this.sort;
      },
      error => {
        console.error('Error fetching lesson list:', error);
      }
    );
  }

  private getEnrollmentTotal(studentAccountId: string): void {
    const subscription = this._drupalRESTService.httpGET('/api_rest/v1/checkStudentBalanceLimit', [
      { parameter: 'studentAccountId', value: studentAccountId }
    ]).subscribe(
      (data) => {
        this.enrollmentBalance = data
      },
      error => {
        console.error('Error fetching enrollment balance:', error);
      }
    );
    this.subscriptions.push(subscription);
  }

  private getCurrentEnrollment(studentAccountID: string): void {
    const subscription = this._drupalRESTService.httpGET('/api_rest/v1/getCurrentEnrollment', [{ parameter: 'studentAccountID', value: studentAccountID }])
      .subscribe((data) => {
        this.currentEnrollment = data;
        console.log("currentEnrollment", data);
      }, error => {
        // Handle the error
      });
    this.subscriptions.push(subscription);
  }

  private getOverallEnrollmentBalance(studentAccountID: string): void {
    const clientCurrentDate = new Date().toLocaleString();
    const subscription = this._drupalRESTService.httpGET('/api_rest/v1/getOverallEnrollmentBalance', [
      { parameter: 'studentAccountID', value: studentAccountID },
      { parameter: 'clientCurrentDate', value: clientCurrentDate }
    ]).subscribe((data) => {
      this.overallEnrollmentBalance = data;
      this.initializeTippy();
    }, error => {
      // Handle the error
    });
    this.subscriptions.push(subscription);
  }

  private getBadgeInfoStudentInfo(studentAccountID: string): void {
    const subscription = this._drupalRESTService.httpGET('/api_rest/v1/getBadgeInfoStudentInfo', [{ parameter: 'studentAccountID', value: studentAccountID }])
      .subscribe((data) => {
        this.studentBadgeInfo = data;
      }, error => {
        // Handle the error
      });
    this.subscriptions.push(subscription);
  }

  private getNextLessonScheduled(studentAccountID: string): void {
    const subscription = this._drupalRESTService.httpGET('/api_rest/v1/getNextLessonScheduled', [{ parameter: 'studentAccountID', value: studentAccountID }])
      .subscribe((data) => {
        this.nextLessonScheduled = data;
      }, error => {
        // Handle the error
      });
    this.subscriptions.push(subscription);
  }

  formatNextLesson(lessonData: any): string {
    if (!lessonData || !lessonData.field_date_and_time || lessonData.field_date_and_time.length === 0) {
      return 'Not available';
    }

    // Extracting the date from the lesson data
    const lessonDateStr = lessonData.field_date_and_time[0].value;
    const lessonDate = new Date(lessonDateStr);

    // Formatting the date to mm/dd/yy
    const formattedDate = ('0' + (lessonDate.getMonth() + 1)).slice(-2) + '/' +
      ('0' + lessonDate.getDate()).slice(-2) + '/' +
      lessonDate.getFullYear().toString().substr(-2);

    return `${formattedDate}`;
  }

  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
  openDialog(component: ComponentType<unknown>, dialogConfig: { height: string, width: string, closeOnNavigation?: boolean, data?: {} } = { height: "auto", width: "600px", closeOnNavigation: true }) {
    let dialogRef = this._dialogService.openDialog(component, "defaultWithData", dialogConfig).afterClosed().subscribe(data => {
      // Reload the student dashboard.
      this.getStudentDashboard(this.user.id);
    });

    this.subscriptions.push(dialogRef);
  }

  getStudentDashboard(id: number) {
    this.studentDashboardObservable = this._utilityService.getLoadStudentDashboard(id);
    const subscription = this.studentDashboardObservable.subscribe((data) => {
      const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 200);

      console.log("student data", data);
      this.studentAccountID = data['#studentAccountID'][0];

      const studentAccountID = data['#studentAccountID'][0];
      if (studentAccountID) {
        this.getCurrentEnrollment(studentAccountID);
        this.getOverallEnrollmentBalance(studentAccountID);
        this.getNextLessonScheduled(studentAccountID);
        this.getBadgeInfoStudentInfo(studentAccountID);
        this.getEnrollmentTotal(studentAccountID);
      }

      // Student services DataSource
      if (data['#studentServices']['#rows']) {
        // Convert to iterable array for ngFor directive.
        data['#studentServices']['#rows'] = Object.values(data['#studentServices']['#rows']);
        this.studentServicesColumns = ['date', 'time', 'service', 'length', 'teacher', 'status', 'edit'];
        this.studentServices = data['#studentServices'];
        this.studentServicesRows = data['#studentServices']['#rows'];
      }

      // Lesson List DataSource
      // if (data['#student']['#rows']) {
      //   this.lessonList = data['#student'];
      //   this.lessonListColumns = ['date', 'service', 'length', 'teacher', 'status', 'contacts_present', 'taken / rem', 'edit'];
      //   this.lessonListRows = new MatTableDataSource(data?.['#student']?.['#rows']?.reverse());
      //   this.lessonListRows.paginator = this.paginator;
      //   this.lessonListRows.sort = this.sort;

      //   this.lessonList2Rows = new MatTableDataSource(data?.['#lessonList']?.['rows']?.reverse());
      //   this.lessonList2Rows.paginator = this.paginator;
      //   this.lessonList2Rows.sort = this.sort;
      // }

      if (data['#enrollment']['#rows']) {
        // Convert to iterable array for ngFor directive.
        data['#enrollment']['#rows'] = Object.entries(data['#enrollment']['#rows']);
      }

      this.studentDashboardData = this.dataSource = data;

      this.showProgressSpinner = false;
    });

    this.subscriptions.push(subscription);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lessonListRows.filter = filterValue.trim().toLowerCase();

    if (this.lessonListRows.paginator) {
      this.lessonListRows.paginator.firstPage();
    }
  }

  getPreviousURL() {
    // If they came from the same page, just redirect them to students-list page.
    if (this._routeEventsService.previousRoutePath.value.includes("/students")) {
      this.previousUrl = ['/students-list'];
    } else {
      this.previousUrl = [this._routeEventsService.previousRoutePath.value];
    }
    return this.previousUrl;
  }

  initializeTippy(): void {
    const paymentsDetailsHTML = this.generatePaymentsDetailsHTML();
    const tippyInstance = tippy('#paymentDue', {
      content: paymentsDetailsHTML,
      allowHTML: true,
      placement: 'bottom',
      theme: 'light',
      // interactive: true,
      // trigger: 'click',
      // animation: 'scale',
      // arrow: true,
      // hideOnClick: true,
    });

    this.tippyInstances.push(tippyInstance);
  }

  generatePaymentsDetailsHTML(): string {
    const { payments_details } = this.overallEnrollmentBalance;
    let paymentsHTML = payments_details.map(payment => {
      // Formatting the payment date
      const paymentDate = new Date(payment.payment_date);
      const formattedDate = this.formatUTCDate(paymentDate);

      // Formatting status text
      let statusText = payment.status.charAt(0).toUpperCase() + payment.status.slice(1).replace('_', ' ');
      // Determining color based on status
      let color = payment.status === 'past_due' ? 'red' : 'green';

      return `<div style="color: ${color};">
                <strong>Payment Date:</strong> ${formattedDate}<br>
                <strong>Amount:</strong> $${payment.payment_amount}<br>
                <strong>Status:</strong> ${statusText}
              </div>`;
    }).join('<hr>'); // Adding a horizontal line separator between payments for better structure

    return `<div style="padding: 10px;">${paymentsHTML}</div>`; // Additional styling for padding
  }

  formatUTCDate(date: Date): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  }

  getLessonStatuses(): void {
    // Fetch the lesson statuses from the _taxonomyService
    this.lessonStatuses = this._taxonomyService.lesson_status;
  }

  getStatusText(statusId: string): string {
    // Find the status name based on the status ID
    const status = this._taxonomyService.lesson_status.find(s => s.id === statusId);
    return status ? status.name : 'Pending Status';
  }

  updateStatus(lessonId: number, statusId: string): void {
    // Find the lesson in the lessonList2Rows data source
    const lesson = this.lessonList2Rows.data.find(l => l.id === lessonId);

    if (lesson) {
      // Set the isUpdating flag to true to show the spinner
      lesson.isUpdating = true;

      // Prepare the request parameters
      const params = [
        { parameter: 'selectedStatus[' + lesson.attendees_id + ']', value: statusId },
        { parameter: 'dateTime', value: moment().format('YYYY-MM-DD[T]HH:mm:ss') },
        { parameter: 'eventId', value: lessonId.toString() }
      ];

      const subscription = this._drupalRESTService.httpGET('/api_rest/v1/updateStatus', params)
        .subscribe(
          data => {
            console.log(data);
            if (data['success']) {
              // Update the status in the lesson object
              lesson.status = statusId;
              // Show success message using MatSnackBar
              this._snackBar.open('Status updated successfully', 'Close', {
                duration: 3000,
                panelClass: 'success-snackbar'
              });
              // Refresh the data table in "Lesson List"
              // this.getStudentDashboard(this.user.id);
              this.getLessonList();
            } else {
              // Show error message using MatSnackBar
              this._snackBar.open('Failed to update status', 'Close', {
                duration: 3000,
                panelClass: 'error-snackbar'
              });
            }
            // Set the isUpdating flag to false to hide the spinner
            lesson.isUpdating = false;
          },
          error => {
            console.error('Error updating status:', error);
            // Set the isUpdating flag to false to hide the spinner
            lesson.isUpdating = false;

            // Handle specific error status codes
            if (error.status === 406) {
              // Show error message using MatSnackBar
              this._snackBar.open('No lessons available for this enrollment', 'Close', {
                duration: 3000,
                panelClass: 'error-snackbar'
              });
            } else {
              // Show generic error message using MatSnackBar
              this._snackBar.open('An error occurred while updating the status', 'Close', {
                duration: 3000,
                panelClass: 'error-snackbar'
              });
            }
          }
        );
      this.subscriptions.push(subscription);
    }
  }

  getStatusClass(statusId: string): string {
    switch (statusId) {
      case '63':
        return 'amt-bg-gray badge';
      case '61':
        return 'amt-bg-gray badge';
      case '60':
        return 'amt-bg-orange badge';
      case '64':
        return 'amt-bg-red badge';
      case '62':
        return 'amt-bg-purple badge';
      case '59':
        return 'amt-bg-green badge';
      case '':
        return 'amt-bg-red badge';
      default:
        return 'amt-bg-red badge';
    }
  }

  override openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}, callback?: () => void) {
    console.log('fieldsData', fieldsData)
    console.log('action', action)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
        callback: callback
      },
    }).afterClosed().subscribe(data => {
      // Refresh the student dashboard.
      this.getStudentDashboard(this.user.id);
    });
  }
}
