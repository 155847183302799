import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { HttpClient } from '@angular/common/http';

interface StaffReportHeader {
  reportType: string;
  preparedBy: string;
  year: number;
  weekNumber: number;
  dateRange: string;
  dateRangeStart: string;
  dateRangeEnd: string;
}

interface StaffPerformanceData {
  name: string;
  staff_type: string;
  'de/bonus': number;
  number_of_guest: number;
  private_lessons: number;
  number_in_class: number;
  interview_department: number;
  renewal_department: number;
  dor_sanct_competition: number;
  showcase_medal_ball: number;
  party_time_non_unit: number;
}

interface StaffReportResponse {
  '#theme': string;
  '#staff_header_table': {
    staff_data_header: {
      '#header': Array<{ data: string }>;
      '#rows': Array<any>;
    }
  };
  '#staff_content_table': {
    staff_data_4_system: {
      '#header': Array<{ data: string }>;
      '#rows': Array<StaffPerformanceData>;
    }
  };
}

interface PayrollEntry {
  name: string;
  sessionCount: number;
  amountPaid?: number;
  total?: number;
  type: 'de' | 'preori' | 'frontPrivate' | 'backPrivate' | 'bonusPrivate' | 'subtotal' | 'service' | 'schedule';
}

interface InstructorPayroll {
  instructorName: string;
  jobTitle: string;
  entries: PayrollEntry[];
}

interface PayrollRates {
  de: number;
  frontPrivate: number;
  backPrivate: number;
  bonusPrivate: number;
}

@Component({
  selector: 'app-payroll-report-simple',
  templateUrl: './payroll-report-simple.component.html',
  styleUrls: ['./payroll-report-simple.component.css']
})
export class PayrollReportSimpleComponent extends ViewTableComponent implements OnInit {
  override displayedColumns: string[] = [
    'name',
    'sessionCount',
    'amountPaid',
    'total'
  ];

  override instructorData: InstructorPayroll[] = [];
  year: any;
  week: any;

  private readonly payrollRates: PayrollRates = {
    de: 25,
    frontPrivate: 35,
    backPrivate: 30,
    bonusPrivate: 40
  };

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year'];
      } else {
        this.year = moment().format("YYYY");
      }

      if (data['week']) {
        this.week = data['week'];
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    });
  }

  onYearChange(event: any): void {
    this.year = event.value;
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
  }

  override getData(params: { parameter: string; value: string; }[]): void {
    this.ShowProgressBar = true;
    this.queryParams = params;

    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams);
    }

    // Convert params array to query string
    const queryString = params
      .map(p => `${p.parameter}=${p.value}`)
      .join('&');

    this._utilityService.getStaffPerformanceReport([
      { parameter: 'week', value: this.week },
      { parameter: 'year', value: this.year },
    ])
      .subscribe({
        next: (response) => {
          this.ShowProgressBar = false;

          // Extract header data
          const headerRow = response['#staff_header_table'].staff_data_header['#rows'][0];
          this.data = {
            prepared_by: headerRow[1],
            date_range: {
              start: new Date(headerRow[5]),
              end: new Date(headerRow[6])
            }
          };

          // Transform staff data into instructor format
          this.instructorData = response['#staff_content_table'].staff_data_4_system['#rows']
            .map(staff => ({
              instructorName: staff.name,
              jobTitle: staff.staff_type,
              entries: [
                {
                  name: "DE's/Bonus",
                  sessionCount: staff['de/bonus'],
                  type: 'de',
                  amountPaid: this.payrollRates.de,
                  total: staff['de/bonus'] * this.payrollRates.de
                },
                {
                  name: "Private Lessons",
                  sessionCount: staff.private_lessons,
                  type: 'frontPrivate',
                  amountPaid: this.payrollRates.frontPrivate,
                  total: staff.private_lessons * this.payrollRates.frontPrivate
                },
                {
                  name: "Number in Class",
                  sessionCount: staff.number_in_class,
                  type: 'backPrivate',
                  amountPaid: this.payrollRates.backPrivate,
                  total: staff.number_in_class * this.payrollRates.backPrivate
                },
                {
                  name: "Subtotal Lessons:",
                  sessionCount: Number(staff['de/bonus']) +
                              Number(staff.private_lessons) +
                              Number(staff.number_in_class),
                  type: 'subtotal',
                  amountPaid: undefined,
                  total: (Number(staff['de/bonus']) * this.payrollRates.de) +
                         (Number(staff.private_lessons) * this.payrollRates.frontPrivate) +
                         (Number(staff.number_in_class) * this.payrollRates.backPrivate)
                },
                {
                  name: "Only List Scheduled Events Used (Services)",
                  sessionCount: staff.interview_department + staff.renewal_department,
                  type: 'service',
                  total: staff.interview_department + staff.renewal_department
                },
                {
                  name: "Only List Scheduled Events Used (Schedules)",
                  sessionCount: staff.dor_sanct_competition + staff.showcase_medal_ball + staff.party_time_non_unit,
                  type: 'schedule',
                  total: staff.dor_sanct_competition + staff.showcase_medal_ball + staff.party_time_non_unit
                }
              ]
            }));
        },
        error: (error) => {
          this.ShowProgressBar = false;
          console.error('Error fetching staff performance data:', error);
          // Handle error appropriately
        }
      });
  }

  refreshData(): void {
    this.getData(this.queryParams);
  }

  exportReport(): void {
    // Implement export functionality
    console.log('Exporting report...');
  }

  override resetForm(form?: NgForm): void {
    if (form) {
      form.resetForm();
    }
    this.getData([]);
  }
}
